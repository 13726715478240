import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import AddIcon from "@mui/icons-material/Add";
import Button from "../../UI/Button/Button";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import {
  Contract,
  ListAllOperations,
  useGameslotDeleteMutation,
  useGameslotRelationalLazyQuery,
  useGameslotRelationalQuery,
  useGetRulesPaginatedLazyQuery,
  useVenueOverviewFacilityDescendantsLazyQuery,
  useVenuesFacilityWithFilterQuery,
  useVenuesManagementQuery,
  VenueOverviewFacilityDescendantsQuery,
  VenueOverviewQuery,
} from "../../../generated/graphql";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import {
  useVenueOverviewContext,
  VenueOverviewContextType,
  VenueOverviewFilterSchema,
  VenueOverviewRow,
  VenueOverviewValidDay,
} from "../../../context/VenueOverviewContext";
import { ZodFormattedError } from "zod";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import { dateWithoutTimezone } from "../../../utils/timeFunctions";
import { DatePicker } from "../../UI/shadcn/Time/date-picker";
import dayjs, { Dayjs } from "dayjs";
import minMax from "dayjs/plugin/minMax";
import { getVenueTypes } from "../../../app/venueMasterSlice";
import Card from "../../UI/Card/Card";
import Body1 from "../../UI/Text/Body/Body1";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import { DataTable } from "../../UI/Table/DataTable";
import { ColumnDef, Row } from "@tanstack/react-table";
import { clamp } from "lodash";
import { cn } from "../../../lib/utils";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuShortcut,
  ContextMenuTrigger,
} from "../../UI/shadcn/context-menu";
import { VenueOverviewDataTable } from "../../UI/Table/VenueOverviewDataTable";

import Alert from "../../UI/Alerts/Alert";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../UI/shadcn/popover";
import { Button as ShadcnButton } from "../../UI/shadcn/button";
import VenueOverviewCreateGameslot from "./VenueOverviewCreateGameslot";
import VenueOverviewUpdateGameslot from "./VenueOverviewUpdateGameslot";

dayjs.extend(minMax);

type Venue =
  VenueOverviewFacilityDescendantsQuery["venueOverviewFacilityDescendants"]["venues"][0];

const VenueOverview: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  /*** STATES ***/
  const {
    filter,
    setFilter,
    venueOverviewData,
    setVenueOverviewData,
    showCreateGameslotModal,
    setShowCreateGameslotModal,
    showUpdateGameslotModal,
    setShowUpdateGameslotModal,
    setVenueOverviewCreateGameslotForm,
    venueOverviewUpdateGameslotData,
    setVenueOverviewUpdateGameslotData,
    setActiveVenueOverviewRow,
    venueOverviewCreateGameslotFormPreview,
    venueOverviewCreateGameslotFormErrors,
    activeVenueOverviewRow,
  } = useVenueOverviewContext();
  const [filterZodErrors, setFilterZodErrors] = useState<
    ZodFormattedError<VenueOverviewContextType, string>
  >({ _errors: [] });

  /*** QUERIES ***/
  // Query to get whatever will be dislayed on the table
  const [VenueOverviewQuery, { loading: loadingVenueOverview }] =
    useVenueOverviewFacilityDescendantsLazyQuery();
  const [GameslotQuery, { loading: loadingGameslots }] =
    useGameslotRelationalLazyQuery({
      notifyOnNetworkStatusChange: true,
    });
  const { loading: loadingVenues, data: dataVenues } =
    useVenuesFacilityWithFilterQuery({
      variables: {
        venueFilters: {
          regionId: [filter.regionId],
          typeId: filter.venueTypeId,
        },
      },
    });
  /*** MUTATIONS ***/

  const [GameslotDelete, { loading: loadingGameslotDelete }] =
    useGameslotDeleteMutation();

  /*** USE EFFECTS ***/

  /*** UTILITY FUNCTIONS ***/

  async function handleApplyFilter() {
    const result = VenueOverviewFilterSchema.safeParse(filter);
    if (!result.success) {
      setFilterZodErrors(result.error.format());
      dispatch(displayAlertWarning("There was an issue the filter"));
      return;
    }
    await GameslotQuery({
      fetchPolicy: "no-cache",
      variables: {
        gameslotVenueOverviewFilters: {
          venueId: filter.venueId,
          startDate: dateWithoutTimezone(filter.startDate),
          endDate: dateWithoutTimezone(filter.endDate),
        },
      },
      onCompleted: (data) => {
        setVenueOverviewData((prevState) => ({
          ...prevState,
          currentGameslots: data.gameslotRelational,
        }));
      },
    });

    // Creates or updates data
    await VenueOverviewQuery({
      fetchPolicy: "no-cache",
      variables: {
        venueOverviewFacilityDescendantsInput: {
          regionId: filter.regionId,
          venueId: filter.venueId,
          startDate: dateWithoutTimezone(filter.startDate),
          endDate: dateWithoutTimezone(filter.endDate),
        },
      },
      onCompleted: async (data) => {
        // If none of the venues have contract items set the data to empty
        if (
          data.venueOverviewFacilityDescendants.venues.every(
            (venue) => venue.contractItems == null
          )
        ) {
          setVenueOverviewData({
            allDaysInRange: new Map([]),
            venueRows: [],
            currentGameslots: [],
          });
          return;
        }

        // Now get the ordered venues starting from the root venues (parentId null)
        const orderedVenues = getVenuesInHierarchyOrder(null, data);
        setVenueOverviewData((prevState) => ({
          ...prevState,
          allDaysInRange: allDaysInRangeSorted(data),
          venueRows: orderedVenues
            .filter((venue) => {
              const contractItems = getAllContractItemsForVenue(venue, data);
              return contractItems != null && contractItems.length > 0;
            })
            .map((venue) => {
              // Get an array of all the contract items from all ancestors including this venues contract items
              const allContractItems = getAllContractItemsForVenue(venue, data);
              if (allContractItems) {
                allContractItems.sort(
                  (a, b) =>
                    dayjs(a.startDateTimeLocal).unix() -
                    dayjs(b.startDateTimeLocal).unix()
                );
              }
              return {
                venue: venue,
                contract: allContractItems![0].contract,
                contractItems: allContractItems!!,
                hoursOfOperation: new Set(
                  allContractItems!
                    .map((item) => {
                      // Get all the hours from the beginning to the end of the contract items
                      const start = dayjs(item.startDateTimeLocal);
                      const end = dayjs(item.endDateTimeLocal);
                      // If the start and end are different then it's an overnight contact
                      let hours: number[] = [];
                      for (
                        let i = start.hour();
                        start.date() !== end.date() ? i <= 23 : i < end.hour();
                        i++
                      ) {
                        hours.push(i);
                      }
                      if (start.date() !== end.date()) {
                        for (
                          let i = 0;
                          end.minute() > 0 ? i <= end.hour() : i < end.hour();
                          i++
                        ) {
                          hours.push(i);
                        }
                      }
                      return hours;
                    })
                    .flat()
                ),
              };
            }),
        }));
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });

    return;
  }

  async function handleDeleteGameslot(id: number) {
    await GameslotDelete({
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
      refetchQueries: [ListAllOperations.Query.GameslotRelational],
    });
  }

  function allDaysInRangeSorted(data: VenueOverviewFacilityDescendantsQuery) {
    // Get all the contract items from each venue
    const allContractItems = data.venueOverviewFacilityDescendants.venues
      .filter(
        (
          venue
        ): venue is typeof venue & {
          contractItems: NonNullable<typeof venue.contractItems>;
        } => venue.contractItems !== null && venue.contractItems !== undefined
      )
      .flatMap((venue) => venue.contractItems);

    //  Sort the contract items by startDateTimeLocal
    const sortedContractItems = allContractItems.sort(
      (a, b) =>
        dayjs(a.startDateTimeLocal).unix() - dayjs(b.startDateTimeLocal).unix()
    );

    // Step 3: Create the allDaysInRange Map from the sorted contract items
    const allDaysInRange = new Map<string, VenueOverviewValidDay>(
      sortedContractItems.map((item) => [
        dayjs(item.startDateTimeLocal).format("YYYY-MM-DD"),
        {
          startDate: dayjs(item.startDateTimeLocal),
          endDate: dayjs(item.endDateTimeLocal),
        } as VenueOverviewValidDay,
      ])
    );
    return allDaysInRange;
  }

  // Recursive function to get venues in hierarchical order
  function getVenuesInHierarchyOrder(
    parentId: number | null,
    data: VenueOverviewFacilityDescendantsQuery
  ): Venue[] {
    // Build a parent to children map
    const parentToChildrenMap = new Map<number | null, Venue[]>();

    // Initialize the map
    data.venueOverviewFacilityDescendants.venues.forEach((venue) => {
      const parentId = venue.parentId || null;
      if (!parentToChildrenMap.has(parentId)) {
        parentToChildrenMap.set(parentId, []);
      }
      parentToChildrenMap.get(parentId)!.push(venue);
    });

    const children = parentToChildrenMap.get(parentId) || [];
    let orderedVenues: Venue[] = [];
    for (const child of children) {
      orderedVenues.push(child);
      orderedVenues = orderedVenues.concat(
        getVenuesInHierarchyOrder(child.id, data)
      );
    }
    return orderedVenues;
  }
  // Function to get all contract items from a venue and its ancestors
  function getAllContractItemsForVenue(
    venue: Venue,
    data: VenueOverviewFacilityDescendantsQuery
  ): VenueOverviewFacilityDescendantsQuery["venueOverviewFacilityDescendants"]["venues"][0]["contractItems"] {
    // Build a map of venue id to venue for quick lookup
    const venueMap = new Map<number, Venue>();
    data.venueOverviewFacilityDescendants.venues.forEach((venue) => {
      venueMap.set(venue.id, venue);
    });
    let contractItems: VenueOverviewFacilityDescendantsQuery["venueOverviewFacilityDescendants"]["venues"][0]["contractItems"] =
      [];
    let currentVenue: Venue | undefined = venue;
    while (currentVenue) {
      if (currentVenue.contractItems) {
        contractItems = contractItems.concat(currentVenue.contractItems);
      }
      if (currentVenue.parentId) {
        currentVenue = venueMap.get(currentVenue.parentId);
      } else {
        currentVenue = undefined;
      }
    }
    return contractItems;
  }

  function calculateContractItemsOverlayForDay({
    row,
    day,
    hour,
  }: {
    row: VenueOverviewRow;
    day: string;
    hour: number;
  }): { percentageOverlap: number; isExcluded: boolean; isOvernight: boolean } {
    // For each hour, calculate overlap with contract items
    const contractItemsForDay = row.contractItems.filter(
      (item) => dayjs(item.startDateTimeLocal).format("YYYY-MM-DD") === day
    );
    const isOvernight = hour < 10 && hour >= 0;
    // Define the time range for the hour
    const hourStart = dayjs(day)
      .hour(hour)
      .add(isOvernight ? 1 : 0, "day")
      .minute(0)
      .second(0);
    const hourEnd = hourStart.add(1, "hour").add(isOvernight ? 1 : 0, "day");
    let totalOverlapMinutes = 0;
    let isExcluded = false;

    contractItemsForDay.forEach((item) => {
      const itemStart = dayjs(item.startDateTimeLocal);
      const itemEnd = dayjs(item.endDateTimeLocal);

      // Ensure itemStart and itemEnd are valid
      if (!itemStart.isValid() || !itemEnd.isValid()) {
        return;
      }
      let overlapStart: Dayjs;
      let overlapEnd: Dayjs;
      let overlap: number;

      overlapStart = dayjs.max(hourStart, itemStart) || hourStart;
      overlapEnd = dayjs.min(hourEnd, itemEnd) || hourEnd;
      overlap = overlapEnd.diff(overlapStart, "minutes");

      if (overlap > 0) {
        totalOverlapMinutes += overlap;
      }
      // Only exlude the hour if there is overlapping time for an item that is exluded
      if (item.isExcluded && overlap > 0) {
        isExcluded = true;
        totalOverlapMinutes = 0;
      }
    });
    totalOverlapMinutes = clamp(totalOverlapMinutes, 0, 60);

    const percentageOverlap = (totalOverlapMinutes / 60) * 100; // Each hour has 60 minutes

    return {
      percentageOverlap,
      isExcluded,
      isOvernight,
    };
  }

  function calculateGameslotPreviewOverlapsForVenueAndDay({
    venueId,
    day,
    startHour,
    endHour,
    totalMinutes,
    totalHeight,
  }: {
    venueId: number;
    day: string;
    startHour: number;
    endHour: number;
    totalMinutes: number;
    totalHeight: number;
  }) {
    // Adjust dayEnd for overnight times
    let dayStart = dayjs(day).hour(startHour).minute(0).second(0);
    let dayEnd = dayjs(day).hour(endHour).minute(0).second(0);

    if (endHour <= startHour) {
      // Crosses midnight, add one day to dayEnd
      dayEnd = dayEnd.add(1, "day");
    }

    const gameslotsForVenue = venueOverviewCreateGameslotFormPreview.filter(
      (gameslot) => gameslot.venueId === venueId
    );

    const overlaps: {
      offsetPixels: number;
      heightPixels: number;
      gameslot: {
        venueId: number;
        startDateTimeLocal: string;
        duration: number;
      };
    }[] = [];

    gameslotsForVenue.forEach((gameslot) => {
      const gameslotStartTime = dayjs(gameslot.startDateTimeLocal);
      const gameslotEndTime = gameslotStartTime.add(
        gameslot.duration,
        "minutes"
      );

      // Calculate overlap with the day
      const overlapStart =
        dayjs.max(dayStart, gameslotStartTime) || gameslotStartTime;
      const overlapEnd = dayjs.min(dayEnd, gameslotEndTime) || gameslotEndTime;
      const overlapMinutes = overlapEnd.diff(overlapStart, "minutes");

      if (overlapMinutes > 0) {
        const offsetMinutes = overlapStart.diff(dayStart, "minutes");
        const durationMinutes = overlapMinutes;

        const offsetPixels = (offsetMinutes / totalMinutes) * totalHeight;
        const heightPixels = (durationMinutes / totalMinutes) * totalHeight;

        overlaps.push({
          offsetPixels,
          heightPixels,
          gameslot,
        });
      }
    });

    return overlaps;
  }

  function calculateCurrentGameslotOverlapsForVenueAndDay({
    venueId,
    day,
    startHour,
    endHour,
    totalMinutes,
    totalHeight,
  }: {
    venueId: number;
    day: string;
    startHour: number;
    endHour: number;
    totalMinutes: number;
    totalHeight: number;
  }) {
    // Adjust dayEnd for overnight times
    let dayStart = dayjs(day).hour(startHour).minute(0).second(0);
    let dayEnd = dayjs(day).hour(endHour).minute(0).second(0);

    if (endHour <= startHour) {
      // Crosses midnight, add one day to dayEnd
      dayEnd = dayEnd.add(1, "day");
    }

    const gameslotsForVenue = venueOverviewData.currentGameslots.filter(
      (gameslot) => gameslot.venueId === venueId
    );

    const overlaps: {
      offsetPixels: number;
      heightPixels: number;
      gameslot: {
        venueId: number;
        startDateTimeLocal: string;
        duration: number;
        id: number;
      };
    }[] = [];

    gameslotsForVenue.forEach((gameslot) => {
      const gameslotStartTime = dayjs(gameslot.startDateTimeLocal);
      const gameslotEndTime = gameslotStartTime.add(
        gameslot.duration,
        "minutes"
      );

      // Calculate overlap with the day
      const overlapStart =
        dayjs.max(dayStart, gameslotStartTime) || gameslotStartTime;
      const overlapEnd = dayjs.min(dayEnd, gameslotEndTime) || gameslotEndTime;
      const overlapMinutes = overlapEnd.diff(overlapStart, "minutes");

      if (overlapMinutes > 0) {
        const offsetMinutes = overlapStart.diff(dayStart, "minutes");
        const durationMinutes = overlapMinutes;

        const offsetPixels = (offsetMinutes / totalMinutes) * totalHeight;
        const heightPixels = (durationMinutes / totalMinutes) * totalHeight;

        overlaps.push({
          offsetPixels,
          heightPixels,
          gameslot,
        });
      }
    });

    return overlaps;
  }

  function calculateRelationalBlockedGameslotOverlapsForVenueAndDay({
    venueId,
    relationalVenueIds,
    day,
    startHour,
    endHour,
    totalMinutes,
    totalHeight,
  }: {
    venueId: number;
    relationalVenueIds: number[];
    day: string;
    startHour: number;
    endHour: number;
    totalMinutes: number;
    totalHeight: number;
  }) {
    // Adjust dayEnd for overnight times
    let dayStart = dayjs(day).hour(startHour).minute(0).second(0);
    let dayEnd = dayjs(day).hour(endHour).minute(0).second(0);

    if (endHour <= startHour) {
      // Crosses midnight, add one day to dayEnd
      dayEnd = dayEnd.add(1, "day");
    }

    const gameslotsForVenue = venueOverviewData.currentGameslots.filter(
      (gameslot) =>
        relationalVenueIds.some(
          (relationalVenueId) => relationalVenueId === gameslot.venueId
        ) && venueId !== gameslot.venueId
    );

    const overlaps: {
      offsetPixels: number;
      heightPixels: number;
      gameslot: {
        venueId: number;
        startDateTimeLocal: string;
        duration: number;
        id: number;
      };
    }[] = [];

    gameslotsForVenue.forEach((gameslot) => {
      const gameslotStartTime = dayjs(gameslot.startDateTimeLocal);
      const gameslotEndTime = gameslotStartTime.add(
        gameslot.duration,
        "minutes"
      );

      // Calculate overlap with the day
      const overlapStart =
        dayjs.max(dayStart, gameslotStartTime) || gameslotStartTime;
      const overlapEnd = dayjs.min(dayEnd, gameslotEndTime) || gameslotEndTime;
      const overlapMinutes = overlapEnd.diff(overlapStart, "minutes");

      if (overlapMinutes > 0) {
        const offsetMinutes = overlapStart.diff(dayStart, "minutes");
        const durationMinutes = overlapMinutes;

        const offsetPixels = (offsetMinutes / totalMinutes) * totalHeight;
        const heightPixels = (durationMinutes / totalMinutes) * totalHeight;

        overlaps.push({
          offsetPixels,
          heightPixels,
          gameslot,
        });
      }
    });

    return overlaps;
  }

  const cols: ColumnDef<VenueOverviewRow>[] = [
    {
      header: "Id",
      id: "id",
      cell: ({ row }) => {
        return <Body1 className="w-8 min-w-8">{row.original.venue.id}</Body1>;
      },
      maxSize: 32,
      size: 32,
    },
    {
      header: "Name",
      id: "name",
      cell: ({ row }) => {
        return (
          <div className="w-40 h-full transition-colors min-w-40 hover:bg-info-90">
            <ContextMenu>
              <ContextMenuTrigger className="flex items-center justify-center h-full">
                <Body1>{row.original.venue.name}</Body1>
              </ContextMenuTrigger>
              <ContextMenuContent className="">
                <ContextMenuItem
                  inset
                  onClick={() => {
                    if (row.original.venue.parentId) {
                      navigate(
                        `/ops/sub-venue-management/${row.original.venue.parentId}/${row.original.venue.id}`
                      );
                    } else {
                      navigate(
                        `/ops/venue-management/${row.original.venue.id}`
                      );
                    }
                  }}
                >
                  Venue
                </ContextMenuItem>
                <ContextMenuItem
                  inset
                  onClick={() => {
                    navigate(`/ops/edit-contract/${row.original.contract.id}`);
                  }}
                >
                  Contract
                </ContextMenuItem>
                <ContextMenuItem
                  inset
                  onClick={() => {
                    setShowCreateGameslotModal(true);
                    setShowUpdateGameslotModal(false);
                    setVenueOverviewUpdateGameslotData(undefined);
                    setActiveVenueOverviewRow((prevState) => ({
                      ...row.original,
                    }));
                    setVenueOverviewCreateGameslotForm((prevState) => ({
                      ...prevState,
                      venue: {
                        id: row.original.venue.id,
                        name: row.original.venue.name,
                      },
                      startDateTimeLocal: row.original.contractItems.sort(
                        (a, b) =>
                          dayjs(a.startDateTimeLocal).unix() -
                          dayjs(b.startDateTimeLocal).unix()
                      )[0].startDateTimeLocal,
                      duration: 60,
                      weeks: 1,
                      numberOfGameslots: 1,
                    }));
                  }}
                >
                  Create Gameslot
                </ContextMenuItem>
              </ContextMenuContent>
            </ContextMenu>
          </div>
        );
      },
      maxSize: 160,
      size: 160,
    },
    {
      header: "Time",
      id: "time",
      cell: ({ row }) => {
        return (
          <div className="flex flex-col w-20 min-w-20">
            {Array.from(row.original.hoursOfOperation.values()).map((hour) => {
              return (
                <div
                  key={hour}
                  className="relative w-full h-8 outline outline-neutral-70"
                >
                  <Body1 key={hour}>{hour}:00</Body1>
                </div>
              );
            })}
          </div>
        );
      },
      size: 80,
      maxSize: 80,
    },
    // Dynamically add columns for each day
    ...Array.from(venueOverviewData.allDaysInRange.keys()).map((day) => {
      return {
        header: day,
        id: day,
        cell: ({ row }: { row: Row<VenueOverviewRow> }) => {
          // Inside your column definition for each day
          const hoursArray = Array.from(row.original.hoursOfOperation.values());

          let startHour = hoursArray[0];
          let endHour = hoursArray[hoursArray.length - 1] + 1; // Include the last hour

          // Adjust for overnight hours
          let totalHours;
          if (endHour <= startHour) {
            totalHours = 24 - startHour + endHour; // Crosses midnight
          } else {
            totalHours = endHour - startHour;
          }

          // Generate adjustedHoursArray covering the time range
          let adjustedHoursArray = [];
          for (let i = 0; i < totalHours; i++) {
            let hour = (startHour + i) % 24;
            adjustedHoursArray.push(hour);
          }

          const totalMinutes = totalHours * 60;
          const totalHeight = adjustedHoursArray.length * 32; // Assuming each hour cell is 32px high

          // Calculate overlaps for game slots
          const overlaps = calculateGameslotPreviewOverlapsForVenueAndDay({
            venueId: row.original.venue.id,
            day,
            startHour,
            endHour,
            totalMinutes,
            totalHeight,
          });
          const currentOverlaps =
            calculateCurrentGameslotOverlapsForVenueAndDay({
              venueId: row.original.venue.id,
              day,
              startHour,
              endHour,
              totalMinutes,
              totalHeight,
            });
          const relationalOverlaps =
            calculateRelationalBlockedGameslotOverlapsForVenueAndDay({
              venueId: row.original.venue.id,
              relationalVenueIds: row.original.venue.relationalVenues.map(
                (relationalVenue) => relationalVenue.id
              ),
              day,
              startHour,
              endHour,
              totalMinutes,
              totalHeight,
            });

          return (
            <div className="relative flex flex-col">
              {Array.from(row.original.hoursOfOperation.values()).map(
                (hour) => {
                  const {
                    percentageOverlap: percentageOverlapCi,
                    isExcluded,
                    isOvernight: isOvernightCi,
                  } = calculateContractItemsOverlayForDay({
                    row: row.original,
                    hour: hour,
                    day: day,
                  });
                  return (
                    <ContextMenu>
                      <ContextMenuTrigger
                        disabled={isExcluded || percentageOverlapCi === 0}
                      >
                        <div
                          key={hour}
                          className={cn(
                            "relative w-full h-8 outline outline-neutral-70 bg-white",
                            isExcluded
                              ? "bg-neutral-50"
                              : percentageOverlapCi < 100
                              ? "bg-white hover:bg-success-80"
                              : "bg-success-90 hover:bg-success-80"
                          )}
                        >
                          <div
                            className={cn(
                              "absolute left-0 w-full h-full",
                              isOvernightCi ? "top-0" : "bottom-0",
                              percentageOverlapCi > 0 &&
                                percentageOverlapCi < 100 &&
                                "bg-success-90"
                            )}
                            style={{
                              height: `${
                                isExcluded ? 100 : percentageOverlapCi
                              }%`,
                            }}
                          ></div>
                          <Body1 className="relative pl-1 min-w-40">
                            {isExcluded
                              ? "exclusion"
                              : percentageOverlapCi === 100
                              ? "available"
                              : percentageOverlapCi === 0
                              ? "unavailable"
                              : "buffer"}
                          </Body1>
                        </div>
                      </ContextMenuTrigger>
                      <ContextMenuContent>
                        <ContextMenuItem
                          onClick={() => {
                            setShowCreateGameslotModal(true);
                            setShowUpdateGameslotModal(false);
                            setVenueOverviewUpdateGameslotData(undefined);
                            setActiveVenueOverviewRow((prevState) => ({
                              ...row.original,
                            }));
                            setVenueOverviewCreateGameslotForm((prevState) => ({
                              ...prevState,
                              venue: {
                                id: row.original.venue.id,
                                name: row.original.venue.name,
                              },
                              startDateTimeLocal: dayjs(day)
                                .startOf("hour")
                                .set("hour", hour)
                                .format("YYYY-MM-DD HH:mm:ss"),
                              duration: 60,
                              weeks: 1,
                              numberOfGameslots: 1,
                            }));
                          }}
                        >
                          Create Gameslot Here
                        </ContextMenuItem>
                      </ContextMenuContent>
                    </ContextMenu>
                  );
                }
              )}
              {overlaps.map((overlap, index) => (
                <div
                  className="absolute left-0 z-20 w-full bg-primary-90 outline outline-primary-90"
                  style={{
                    top: `${overlap.offsetPixels}px`,
                    height: `${overlap.heightPixels}px`,
                  }}
                  key={index}
                >
                  <Body1>{overlap.gameslot.duration} mins</Body1>
                </div>
              ))}
              {currentOverlaps.map((overlap, index) => (
                <ContextMenu>
                  <ContextMenuTrigger>
                    <div
                      className={cn(
                        "absolute left-0 z-10 w-full bg-secondary-90 outline outline-primary-90 hover:bg-secondary-80",
                        venueOverviewUpdateGameslotData?.id ===
                          overlap.gameslot.id && "bg-warning-80"
                      )}
                      style={{
                        top: `${overlap.offsetPixels}px`,
                        height: `${overlap.heightPixels}px`,
                      }}
                      key={index}
                    >
                      <Body1>{overlap.gameslot.duration} mins</Body1>
                    </div>
                  </ContextMenuTrigger>
                  <ContextMenuContent>
                    <ContextMenuItem
                      onClick={() => {
                        setShowCreateGameslotModal(false);
                        setShowUpdateGameslotModal(true);
                        setActiveVenueOverviewRow((prevState) => ({
                          ...row.original,
                        }));
                        setVenueOverviewCreateGameslotForm((prevState) => ({
                          venue: {
                            id: row.original.venue.id,
                            name: row.original.venue.name,
                          },
                          ...overlap.gameslot,
                          weeks: 1,
                          numberOfGameslots: 1,
                        }));
                        setVenueOverviewUpdateGameslotData({
                          ...overlap.gameslot,
                        });
                      }}
                    >
                      Edit
                    </ContextMenuItem>
                    <ContextMenuItem
                      onClick={() => handleDeleteGameslot(overlap.gameslot.id)}
                    >
                      Delete
                    </ContextMenuItem>
                  </ContextMenuContent>
                </ContextMenu>
              ))}
              {relationalOverlaps.map((overlap, index) => (
                <div
                  className={cn(
                    "absolute left-0 z-10 w-full bg-error-90 outline outline-primary-90"
                  )}
                  style={{
                    top: `${overlap.offsetPixels}px`,
                    height: `${overlap.heightPixels}px`,
                  }}
                  key={index}
                >
                  <Body1>In use</Body1>
                </div>
              ))}
            </div>
          );
        },
      };
    }),
  ];

  // Constants for the table
  const tableData = useMemo(() => {
    return venueOverviewData.venueRows;
  }, [venueOverviewData]);
  const columns = useMemo(() => cols, [cols]);

  return (
    <main className="flex flex-col gap-4">
      <div className="flex flex-col gap-4 pb-40">
        <LoadingDialog open={false} />
        <div className="flex flex-row justify-between w-full">
          <Headline1Variable>Venue Overview</Headline1Variable>
        </div>
        {/* Filtering section */}
        <div className="flex flex-row flex-wrap items-end w-full gap-4">
          <div className="max-w-60 min-w-60">
            <FormFieldSelect
              name="regionId"
              label="Region"
              placeholder="Select Region"
              error={filterZodErrors.filter?.regionId !== undefined}
              assistiveText={
                filterZodErrors.filter?.regionId !== undefined
                  ? filterZodErrors.filter?.regionId._errors[0]
                  : ""
              }
              value={filter.regionId.toString() ?? "0"}
              inputChange={(value: string) => {
                setFilter((prevState) => ({
                  ...prevState,
                  venueId: 0,
                  regionId: +value,
                }));
              }}
            >
              {[{ id: "0", name: "Select Region" }, ...selectedRegions]}
            </FormFieldSelect>
          </div>
          <div className="max-w-60 min-w-60">
            <DatePicker
              className="w-full"
              label="Start From Date"
              date={filter.startDate}
              setDate={(date) => {
                if (date) {
                  const newStartDate = dayjs(date)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0);
                  setFilter((prevState) => ({
                    ...prevState,
                    startDate: newStartDate.toDate(),
                  }));
                }
              }}
            />
          </div>
          <div className="max-w-60 min-w-60">
            <DatePicker
              className="w-full"
              label="To Date"
              date={filter.endDate}
              setDate={(date) => {
                if (date) {
                  const newEndDate = dayjs(date)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0);
                  setFilter((prevState) => ({
                    ...prevState,
                    endDate: newEndDate.toDate(),
                  }));
                }
              }}
            />
          </div>
          <div className="max-w-60 min-w-60">
            <FormFieldSelect
              name="venueId"
              label="Venue"
              placeholder="Select Venue"
              error={filterZodErrors.filter?.venueId !== undefined}
              assistiveText={
                filterZodErrors.filter?.venueId !== undefined
                  ? filterZodErrors.filter?.venueId._errors[0]
                  : ""
              }
              value={filter.venueId.toString() ?? "0"}
              inputChange={(value: string) => {
                setFilter((prevState) => ({
                  ...prevState,
                  venueId: +value,
                }));
              }}
              loading={loadingVenues}
            >
              {[
                ...[{ id: "0", name: "Select Venue" }],
                ...(dataVenues !== undefined
                  ? dataVenues.venuesFacility.map((venue) => {
                      return {
                        id: venue.id,
                        name: venue.name,
                      };
                    })
                  : []),
              ]}
            </FormFieldSelect>
          </div>
          <Button
            variant="primary"
            onClick={handleApplyFilter}
            className="h-fit"
          >
            Apply
          </Button>
        </div>
        {venueOverviewCreateGameslotFormErrors.length > 0 && (
          <div className="flex flex-row gap-2">
            <Alert
              variant="error"
              size="small"
              persist={true}
              className="whitespace-nowrap"
              content={`There are ${venueOverviewCreateGameslotFormErrors.length} error(s) with the game slot creation`}
            />
            <Popover>
              <PopoverTrigger asChild>
                <ShadcnButton
                  variant={"outline"}
                  className="h-[46px]"
                >
                  View Errors
                </ShadcnButton>
              </PopoverTrigger>
              <PopoverContent className="flex flex-col w-96">
                <p>
                  The Below game slots do not fit in any contract item slots for{" "}
                  {activeVenueOverviewRow?.venue.name}
                </p>
                {venueOverviewCreateGameslotFormErrors.map((error) => {
                  return <p>{error}</p>;
                })}
              </PopoverContent>
            </Popover>
          </div>
        )}
        {tableData.length > 0 ? (
          <div>
            <VenueOverviewDataTable
              columns={columns}
              data={tableData}
              rowClassName="h-full"
              tableClassName="h-full"
              cellClassName="p-0"
              colsToPin={["id", "name", "time"]}
            />
          </div>
        ) : (
          <Headline2Variable>
            No contract items found with this filter
          </Headline2Variable>
        )}
      </div>

      {showCreateGameslotModal && <VenueOverviewCreateGameslot />}
      {showUpdateGameslotModal && <VenueOverviewUpdateGameslot />}
    </main>
  );
};

export default VenueOverview;
