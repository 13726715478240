import React, { useEffect, useState } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Headline2 from "../UI/Text/Headline/Headline2";
import Body1 from "../UI/Text/Body/Body1";
import { SwitchButton } from "../UI";
import Button from "../UI/Button/Button";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import Checkbox from "../UI/FormControls/Checkbox";
import Card from "../UI/Card/Card";
import { useToast } from "../UI/Toast/use-toast";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SPORTS_BY_USERID } from "../../graphql/queries";
import { UPDATE_USER_NOTIFICATION_SETTINGS } from "../../graphql/queries/user";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import { getUserById } from "../../app/cxSlice";

type notificationFormData = {
  marketingEmail: boolean;
  scoreReminderEmail: boolean;
  gameReminderEmail: boolean;
  registrationOpeningEmail: boolean;
  sportSpecificEmail: boolean;
  [sportId: number]: boolean;
};

const Notification = () => {
  const { isLoading, userById }: any = useSelector(
    (state: RootState) => state.cx
  );
  const [formData, setFormData] = useState<notificationFormData>({
    marketingEmail: false,
    scoreReminderEmail: false,
    gameReminderEmail: false,
    registrationOpeningEmail: false,
    sportSpecificEmail: false,
  });
  const [sportList, setSportList] = useState<{ id: string; name: string }[]>(
    []
  );
  const { toast } = useToast();
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const isEditPlayer = params.id;

  const {
    data: listSportByUserId,
    loading: loadingSportByUserId,
    error: errorSportByUserId,
  } = useQuery(GET_SPORTS_BY_USERID, {
    variables: {
      userId: params.id ? params.id : "",
    },
    onCompleted: (data) => {
      setSportList(data.sportsByUserId);
    },
  });

  const [
    UpdatedUserInfo,
    { data: updatedUserInfo, loading: loadingUpdate, error: errorUpdate },
  ]: any = useMutation(UPDATE_USER_NOTIFICATION_SETTINGS);

  useEffect(() => {
    if (userById) {
      const sportEmailIds = userById.sportEmailIds;
      const sportEmails: { [id: number]: boolean } = {};
      sportEmailIds?.forEach((sportId: number) => {
        sportEmails[sportId] = true;
      });

      setFormData({
        marketingEmail: userById.marketingEmail,
        scoreReminderEmail: userById.scoreReminderEmail,
        gameReminderEmail: userById.gameReminderEmail,
        registrationOpeningEmail: userById.registrationOpeningEmail,
        sportSpecificEmail: userById.sportSpecificEmail,
        ...sportEmails,
      });
    }

    return () => {
      isEditPlayer && dispatch(getUserById(isEditPlayer));
    };
  }, [userById, isEditPlayer]);

  const handleUpdateProfile = () => {
    const formDataKeys = Object.keys(formData);

    const sportEmailIds: number[] = [];
    formDataKeys.forEach((key) => {
      if (parseInt(key) > 0 && formData[parseInt(key)] === true) {
        sportEmailIds.push(parseInt(key));
      }
    });

    UpdatedUserInfo({
      variables: {
        updateNotificationSettingsId: isEditPlayer,
        marketingEmail: formData.marketingEmail,
        scoreReminderEmail: formData.scoreReminderEmail,
        gameReminderEmail: formData.gameReminderEmail,
        registrationOpeningEmail: formData.registrationOpeningEmail,
        sportSpecificEmail: formData.sportSpecificEmail,
        sportEmailIds: sportEmailIds,
      },
    }).then(async (data: any) => {
      const sportEmailIds =
        data.data.updateUserAccount.userAccount.sportEmailIds;
      const sportEmails: { [id: number]: boolean } = {};
      sportEmailIds.forEach((sportId: number) => {
        sportEmails[sportId] = true;
      });

      setFormData((prevState) => ({
        ...prevState,
        marketingEmail: data.data.updateUserAccount.userAccount.marketingEmail,
        scoreReminderEmail:
          data.data.updateUserAccount.userAccount.scoreReminderEmail,
        gameReminderEmail:
          data.data.updateUserAccount.userAccount.gameReminderEmail,
        registrationOpeningEmail:
          data.data.updateUserAccount.userAccount.registrationOpeningEmail,
        sportSpecificEmail:
          data.data.updateUserAccount.userAccount.sportSpecificEmail,
        ...sportEmails,
      }));
      toast({
        title: "Notification Settings Updated",
        description: "Your notification settings has been updated successfully",
      });
    });
  };

  if (loadingUpdate || loadingSportByUserId || isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <main className="flex justify-center min-h-screen bg-surface-bg2">
      <div className="flex flex-col w-full max-w-5xl p-4">
        <Card className="mt-5">
          <div className="w-full flex flex-row justify-between mb-4">
            <div>
              <Headline2 size="s-xs">Score Reminder Emails</Headline2>
              <Body1>
                Score reminder emails go out the morning after your game when no
                scores have been recorded
              </Body1>
            </div>
            <SwitchButton
              labelStart=""
              labelEnd=""
              height="30"
              width="50"
              checked={formData.scoreReminderEmail}
              handleChange={(value) => {
                setFormData({
                  ...formData,
                  scoreReminderEmail: value.target.checked,
                });
              }}
            />
          </div>
          <div className="w-full flex flex-row justify-between mb-4">
            <div>
              <Headline2 size="s-xs">Game Reminder Emails</Headline2>
              <Body1>
                Game reminder emails are sent three days before a game and
                include a prompt for players to report their attendance
              </Body1>
            </div>
            <SwitchButton
              labelStart=""
              labelEnd=""
              height="30"
              width="50"
              checked={formData.gameReminderEmail}
              handleChange={(value) => {
                setFormData({
                  ...formData,
                  gameReminderEmail: value.target.checked,
                });
              }}
            />
          </div>
        </Card>
        <Card className="mt-5">
          <div className="w-full flex flex-row justify-between mb-4">
            <div>
              <Headline2 size="s-xs">Marketing Emails</Headline2>
              <Body1>
                Receive marketing emails from the platform and its partners
              </Body1>
            </div>
            <SwitchButton
              labelStart=""
              labelEnd=""
              height="30"
              width="50"
              checked={formData.marketingEmail}
              handleChange={(value) => {
                setFormData({
                  ...formData,
                  marketingEmail: value.target.checked,
                });
              }}
            />
          </div>
          <div className="w-full flex flex-row justify-between mb-4">
            <div>
              <Headline2 size="s-xs">Registration Opening Emails</Headline2>
              <Body1>
                Get updates when registration opens for a new season
              </Body1>
            </div>
            <SwitchButton
              labelStart=""
              labelEnd=""
              height="30"
              width="50"
              checked={formData.registrationOpeningEmail}
              handleChange={(value) => {
                setFormData({
                  ...formData,
                  registrationOpeningEmail: value.target.checked,
                });
              }}
            />
          </div>
        </Card>
        <Card className="mt-5">
          <div className="flex flex-col md:items-start gap-4 ">
            <div className="w-full flex flex-row justify-between mb-4">
              <div>
                <Headline2 size="s-xs">Sport Specific Emails</Headline2>
                <Body1>
                  Sport specific emails are sent for the sports you choose
                </Body1>
              </div>
              <SwitchButton
                labelStart=""
                labelEnd=""
                height="30"
                width="50"
                checked={formData.sportSpecificEmail}
                handleChange={(value) => {
                  setFormData((prev) => {
                    const newFormData = { ...prev };
                    sportList.forEach((sport) => {
                      newFormData[parseInt(sport.id)] = false;
                    });
                    return {
                      ...newFormData,
                      sportSpecificEmail: value.target.checked,
                    };
                  });
                }}
              />
            </div>
            <div className="flex-col px-8">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
                {sportList.map((sport) => {
                  return (
                    <div key={sport.id}>
                      <Checkbox
                        label={sport.name}
                        defaultChecked={false}
                        checkedState={
                          formData["sportSpecificEmail"] &&
                          formData[parseInt(sport.id)]
                        }
                        inputChange={(val) => {
                          setFormData({
                            ...formData,
                            [parseInt(sport.id)]: val,
                            sportSpecificEmail: true,
                          });
                        }}
                        id={sport.id}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Card>
        <Card className="mt-5">
          <div className="w-full flex flex-row justify-between mb-4">
            <div>
              <Headline2 size="s-xs">Unsubscribe from all emails</Headline2>
              <Body1>
                No game reminders, score reminders, promotional email or any
                other emails
              </Body1>
            </div>
            <SwitchButton
              labelStart=""
              labelEnd=""
              height="30"
              width="50"
              checked={
                !formData.marketingEmail &&
                !formData.scoreReminderEmail &&
                !formData.gameReminderEmail &&
                !formData.registrationOpeningEmail &&
                !formData.sportSpecificEmail
              }
              handleChange={(value) => {
                setFormData({
                  marketingEmail: false,
                  scoreReminderEmail: false,
                  gameReminderEmail: false,
                  registrationOpeningEmail: false,
                  sportSpecificEmail: false,
                });
              }}
            />
          </div>
        </Card>
        {/* Button for Update Profile*/}
        <div className="mt-6">
          <Button
            variant="primary"
            onClick={handleUpdateProfile}
            type="submit"
          >
            Save
          </Button>
        </div>
        <LoadingDialog open={loadingUpdate} />
      </div>
    </main>
  );
};

export default Notification;
