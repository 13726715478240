import { gql } from "@apollo/client";

const GET_SESSIONS_FOR_CAPACITY_GROUP = gql`
  query SessionsForCapacityGroup($sessionFilters: SessionFilters) {
    sessions(sessionFilters: $sessionFilters) {
      id
      dayOfWeek
      startDate
      league {
        name
      }
    }
  }
`;

const GET_SESSION_BY_ID = gql`
  query Session($sessionId: Int!) {
    session(id: $sessionId) {
      setCapacity
      dayOfWeek
      endDate
      exceptionDates
      id
      indyTeams
      league {
        id
        venues {
          id
          name
        }
      }
      maxCapacity
      numberOfWeeks
      priceForIndy
      registrationBatch {
        id
      }
      priceForTeam
      sessionNotes
      startTimes
      startDate
      venues {
        id
      }
      weeksOfPlayoffs
      regSkillChoices {
        id
        name
      }
      capacityGroup {
        id
      }
      isActive
    }
  }
`;

const GET_SESSIONS_FOR_WAYFINDER = gql`
  query SessionsPaginated($sessionsPaginatedInput: SessionsWayfinderInput) {
    sessionsPaginated(sessionsPaginatedInput: $sessionsPaginatedInput) {
      count
      sessions {
        id
        region {
          id
          name
        }
        registrationBatch {
          id
          name
          regOpenDate
        }
        league {
          id
          name
        }
        dayOfWeek
        startDate
        setCapacity
        indyTeams
        teamCount
        freeAgentCount {
          totalCount
          men
          women
          other
        }
        divisions {
          id
          name
        }
      }
    }
  }
`;

gql`
  query SessionsForDivision($sessionFilters: SessionFilters) {
    sessions(sessionFilters: $sessionFilters) {
      id
      dayOfWeek
      startDate
      endDate
      numberOfWeeks
      weeksOfPlayoffs
      exceptionDates
      league {
        name
      }
      teams {
        id
        name
        registration {
          regQuestionChoice {
            regSkillChoice {
              name
            }
          }
        }
      }
      divisions {
        id
        vmColor
        vmName
        weeksOfPlayoffs
        numberOfWeeks
        startDate
        name
        exceptionDates
        endDate
        teams {
          id
          name
        }
      }
    }
  }
`;

export {
  GET_SESSIONS_FOR_CAPACITY_GROUP,
  GET_SESSIONS_FOR_WAYFINDER,
  GET_SESSION_BY_ID,
};
