import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import classes from "./ContractManagement.module.css";
import { useTable, useSortBy, Column } from "react-table";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Session } from "../../types/types";
import {
  getGenderFormat,
  getLeagues,
  getOfficiatedStatus,
  getRegistrationBatch,
  getSessions,
  getSports,
  handleSessionFilter,
} from "../../app/venueMasterSlice";
import BaseTable from "../UI/Table/Table";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Button from "../UI/Button/Button";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import LoadingDialog from "../UI/Dialog/LoadingDialog";

const Sessions: React.FC = () => {
  const dispatch: any = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const rootPath =
    process.env.NODE_ENV === "production" ? process.env.REACT_APP_URL : "";

  const {
    sessions,
    sessionFilter,
    isLoading,
    selectedRegions,
    genderFormat,
    sports,
    officiatedStatuses,
    leagues,
    registrationBatches,
  }: any = useSelector((state: RootState) => state.venueMaster);

  enum DayOfWeek {
    Sunday = 0,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
  }

  const weekdaysArray: any = [
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
  ];

  useEffect(() => {
    dispatch(getGenderFormat(""));
    dispatch(getSports(""));
    dispatch(getOfficiatedStatus(""));
    dispatch(getLeagues(""));
    dispatch(getRegistrationBatch(""));
    dispatch(getSessions(sessionFilter));
  }, [dispatch]);

  const redirectCreateSession = () => {
    navigate(`${rootPath}/ops/session/`);
  };

  const redirectEditSession = (SessionsId: number) => {
    navigate(`${rootPath}/ops/session/${SessionsId}`, {
      state: { SessionsId },
    });
  };

  const onSubmit = () => {
    dispatch(getSessions(sessionFilter));
  };

  const handleChange = (value: string, name: string) => {
    const sessionFilterData = {
      ...sessionFilter,
      [name]: +value || value,
    };
    if (name === "isActive") {
      sessionFilterData.isActive =
        value === "1" ? true : value === "2" ? false : null;
    }
    dispatch(handleSessionFilter(sessionFilterData));
  };

  const handleChangeDayOfWeek = (value: string) => {
    const dayOfWeek = +value;
    dispatch(handleSessionFilter({ ...sessionFilter, dayOfWeek: dayOfWeek }));
  };

  const handleChangeRegion = (value: string) => {
    const regions = +value;
    dispatch(handleSessionFilter({ ...sessionFilter, regions: regions }));
  };

  const COLUMNS: Column<Session>[] = [
    {
      Header: "Region",
      id: "region",
      accessor: (d: any) => {
        return <div>{d.league.sportFormat.region.name}</div>;
      },
    },
    {
      Header: "Sport",
      id: "sport",
      accessor: (d: any) => {
        return <div>{d.league.sportFormat.sport.name}</div>;
      },
    },
    {
      Header: "Season",
      id: "season",
      accessor: (d: any) => {
        return <div>{d.registrationBatch.name}</div>;
      },
    },
    {
      Header: "League",
      id: "league",
      accessor: (d: any) => {
        return <div>{d.league.name}</div>;
      },
    },
    {
      Header: "Day Of Week",
      id: "day",
      accessor: (d: any) => {
        return <div>{DayOfWeek[d.dayOfWeek]}</div>;
      },
    },
    {
      Header: "Start Date",
      id: "startDate",
      accessor: (d: any) => {
        return <div>{moment(d.startDate).local().format("YYYY-MM-DD")}</div>;
      },
    },
    {
      Header: "End Date",
      id: "endDate",
      accessor: (d: any) => {
        return <div>{moment(d.endDate).local().format("YYYY-MM-DD")}</div>;
      },
    },
    {
      Header: "Weeks Of Play",
      id: "weeks",
      accessor: (d: any) => {
        return <div>{d["numberOfWeeks"]}</div>;
      },
    },
    {
      Header: "Weeks Of Playoffs",
      id: "weeksOfPlayoffs",
      accessor: (d: any) => {
        return <div>{d.weeksOfPlayoffs}</div>;
      },
    },
    {
      Header: "Team Price",
      id: "teamPrice",
      accessor: (d: any) => {
        return <div>{d.priceForTeam}</div>;
      },
    },
    {
      Header: "Individual Price",
      id: "individualPrice",
      accessor: (d: any) => {
        return <div>{d.priceForIndy}</div>;
      },
    },
    {
      Header: "Options",
      accessor: (d: any) => {
        return (
          <div className={classes.format__options}>
            <div className={classes.format__option}>
              <Button
                variant="secondary"
                onClick={() => redirectEditSession(d.id)}
              >
                {<EditIcon />}{" "}
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => sessions, [sessions]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
        initialState: {
          hiddenColumns: ["id"],
        },
      },
      useSortBy
    );

  return (
    <main>
      <LoadingDialog open={isLoading} />
      <Headline1Variable>Sessions</Headline1Variable>
      <div className="w-2/3">
        <div className="flex flex-row items-end gap-4">
          <FormFieldSelect
            defaultValue="0"
            inputChange={(value) => {
              handleChangeRegion(value);
            }}
            name="regionId"
            label="Region"
            placeholder="Region"
            value={sessionFilter?.regions?.toString() ?? "0"}
          >
            {[...selectedRegions, ...[{ id: "0", name: "All" }]]}
          </FormFieldSelect>
          <FormFieldSelect
            defaultValue="0"
            inputChange={(value) => {
              handleChange(value, "sportId");
            }}
            name="sportId"
            label="Sport"
            placeholder="Sport"
            value={sessionFilter?.sportId?.toString() ?? "0"}
          >
            {[...sports, ...[{ id: "0", name: "All" }]]}
          </FormFieldSelect>
          <FormFieldSelect
            defaultValue="0"
            inputChange={(value) => {
              handleChange(value, "leagueId");
            }}
            name="leagueId"
            label="League"
            placeholder="League"
            value={sessionFilter?.leagueId?.toString() ?? "0"}
          >
            {[...leagues, ...[{ id: "0", name: "All" }]]}
          </FormFieldSelect>
          <FormFieldSelect
            defaultValue="0"
            inputChange={(value) => {
              handleChangeDayOfWeek(value);
            }}
            name="dayOfWeek"
            label="Day Of Week"
            placeholder="Day Of Week"
            value={sessionFilter?.dayOfWeek?.toString() ?? "7"}
          >
            {[...weekdaysArray, ...[{ id: "7", name: "All" }]]}
          </FormFieldSelect>
        </div>
        <div className="flex flex-row items-end gap-4">
          <FormFieldSelect
            defaultValue="0"
            inputChange={(value) => {
              handleChange(value, "registrationBatchId");
            }}
            name="registrationBatchId"
            label="Season"
            placeholder="Season"
            value={sessionFilter?.registrationBatchId?.toString() ?? "0"}
          >
            {[...registrationBatches, ...[{ id: "0", name: "All" }]]}
          </FormFieldSelect>
          <FormFieldSelect
            defaultValue="0"
            inputChange={(value) => {
              handleChange(value, "genderFormatId");
            }}
            name="genderFormatId"
            label="Gender Format"
            placeholder="Gender Format"
            value={sessionFilter?.genderFormatId?.toString() ?? "0"}
          >
            {[...genderFormat, ...[{ id: "0", name: "All" }]]}
          </FormFieldSelect>
          <FormFieldSelect
            defaultValue="0"
            inputChange={(value) => {
              handleChange(value, "officiatedStatusId");
            }}
            name="officiatedStatusId"
            label="Officiated Status"
            placeholder="Officiated Status"
            value={sessionFilter?.officiatedStatusId?.toString() ?? "0"}
          >
            {[...officiatedStatuses, ...[{ id: "0", name: "All" }]]}
          </FormFieldSelect>
          <FormFieldSelect
            defaultValue="0"
            inputChange={(value) => {
              handleChange(value, "isActive");
            }}
            name="isActive"
            label="Session Status"
            placeholder="Session Status"
            value={
              sessionFilter.isActive === null
                ? "0"
                : sessionFilter.isActive
                ? "1"
                : "2"
            }
          >
            {[
              { id: "0", name: "All" },
              { id: "1", name: "Active" },
              { id: "2", name: "Hidden" },
            ]}
          </FormFieldSelect>
          <Button
            variant="primary"
            onClick={onSubmit}
            className="min-w-[150px]"
          >
            Apply filters
          </Button>
        </div>
      </div>
      <div className="my-5 text-right">
        <Button
          variant="primary"
          onClick={() => redirectCreateSession()}
        >
          <AddIcon />
          New Session
        </Button>
      </div>
      {sessions.length > 0 && (
        // <div className={classes.table__venues}>{SessionsTable}</div>
        <BaseTable
          data={data}
          columns={columns}
        />
      )}
      {!(sessions.length > 0) && (
        <div className={classes.table__contracts}>No Data Found</div>
      )}
    </main>
  );
};

export default Sessions;
