import React, { useEffect, useState } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import {
  DayOfWeek,
  getLeagues,
  getRegistrationBatch,
  getSports,
  SessionFilter,
} from "../../app/venueMasterSlice";
import {
  ListAllOperations,
  Session,
  useProductTypesQuery,
  useUpdateWaitListMutation,
  useWaitListPaginatedQuery,
  useWaitListStatusesQuery,
  WaitListPaginatedDocument,
  WaitList as WaitListType,
} from "../../generated/graphql";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import FormFieldDate from "../UI/FormField/FormFieldDate/FormFieldDate";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import Button from "../UI/Button/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../UI/shadcn/dropdown";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../UI/shadcn/select";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../UI/Table/DataTable";
import { Box, Modal, Pagination } from "@mui/material";
import { MoreVerticalIcon } from "lucide-react";
import FormField from "../UI/FormField/FormField";
import { useToast } from "../UI/Toast/use-toast";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../UI/shadcn/dialog";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";

const weekdaysArray: { id: number; name: string }[] = [
  { id: 0, name: "Sunday" },
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
];

type WaitListFilter = {
  leagueId: number | null;
  dayOfWeek: number | null;
  registrationBatchId: number | null;
  regionId: number | null;
  sportId: number | null;
  startDate: string;
  endDate: string | null;
  productTypeId: number | null;
  statusId: number | null;
};

type PaginationType = {
  page: number;
  pageSize: number;
  pageCount: number | undefined;
};

type List = {
  __typename?: string | undefined;
  id: number | string;
  name: string;
} | null;

const initialWaitListFilter = {
  regionId: null,
  registrationBatchId: null,
  sportId: null,
  dayOfWeek: null,
  leagueId: null,
  startDate: dayjs().format("YYYY-MM-DD"),
  endDate: null,
  productTypeId: null,
  statusId: 1,
};

const WaitList = () => {
  const { toast } = useToast();
  const [searchParams] = useSearchParams();
  const leagueId = searchParams.get("leagueId");
  const startDate = searchParams.get("startDate");

  const [waitListFilter, setWaitListFilter] = useState<WaitListFilter>(
    initialWaitListFilter
  );
  const {
    isLoading,
    selectedRegions,
    registrationBatches,
    sports,
    leagues,
  }: any = useSelector((state: RootState) => state.venueMaster);
  const dispatch = useDispatch<AppDispatch>();
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageSize: 10,
    pageCount: undefined,
  });
  const [waitListData, setWaitListData] = useState<WaitListType[]>([]);
  const [selectedWaitList, setSelectedWaitList] =
    useState<WaitListType | null>();
  const [priorityModal, setPriorityModal] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);
  const [priorityInput, setPriorityInput] = useState<number>(0);
  const [statusIdInput, setStatusIdInput] = useState<number>(0);
  const [productTypesList, setProductTypesList] = useState<List[]>([]);
  const [waitListStatusesList, setWaitListStatusesList] = useState<List[]>([]);

  const {
    data: waitListGet,
    loading,
    error,
  } = useWaitListPaginatedQuery({
    variables: {
      waitListPaginatedInput: {
        page: pagination.page,
        pageSize: pagination.pageSize,
        waitListFilter: {
          ...waitListFilter,
          dayOfWeek:
            waitListFilter.dayOfWeek == -1 ? null : waitListFilter.dayOfWeek,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      setWaitListData(
        (res.waitListPaginated?.waitList
          ? res.waitListPaginated.waitList
          : []) as WaitListType[]
      );
      setPagination((prevState: PaginationType) => {
        return {
          ...prevState,
          pageCount: res.waitListPaginated.count
            ? res.waitListPaginated.count
            : 10,
        };
      });
    },
  });

  const [updateWaitlist, { loading: loadingUpdateWaitList }] =
    useUpdateWaitListMutation({
      refetchQueries: [ListAllOperations.Query.WaitListPaginated],
      onCompleted: (res) => {
        if (res.updateWaitList.success) {
          dispatch(displayAlertSuccess(res.updateWaitList.message));
        } else {
          dispatch(displayAlertError(res.updateWaitList.message));
        }
        setPriorityModal(false);
        setPriorityInput(0);
        setSelectedWaitList(null);
      },
    });

  const {
    data: productTypesData,
    loading: productTypesLoading,
    error: productTypesError,
  } = useProductTypesQuery({
    onCompleted: (res) => {
      if (res.productTypes) {
        setProductTypesList(res.productTypes);
      }
    },
  });

  const {
    data: waitListStatusesData,
    loading: waitListStatusesLoading,
    error: waitListStatusesError,
  } = useWaitListStatusesQuery({
    onCompleted: (res) => {
      if (res.waitListStatuses) {
        setWaitListStatusesList(res.waitListStatuses);
      }
    },
  });

  useEffect(() => {
    if (sports.length === 0) {
      dispatch(getSports(""));
    }
    if (leagues.length === 0) {
      dispatch(getLeagues(""));
    }
    if (registrationBatches.length === 0) {
      dispatch(getRegistrationBatch(""));
    }
  }, [dispatch]);

  useEffect(() => {
    if (leagueId || startDate) {
      setWaitListFilter((prevState: WaitListFilter) => {
        return {
          ...prevState,
          leagueId: leagueId ? +leagueId : null,
          startDate: startDate ?? dayjs().format("YYYY-MM-DD"),
        };
      });
    }
  }, []);

  const waitListColumns: ColumnDef<WaitListType>[] = [
    {
      accessorKey: "id",
      header: () => <div className="text-center">Id</div>,
      cell: ({ row }) => {
        return <p className="font-medium text-center">{row.getValue("id")}</p>;
      },
    },
    {
      accessorKey: "user",
      header: () => <div className="text-left">Player</div>,
      cell: ({ row }) => {
        return (
          <div>
            <p>
              <a
                className="underline cursor-pointer text-info-50"
                href={
                  process.env.NODE_ENV === "production"
                    ? `http://admin.jamsports.com/players/user/${row.original.user.id}`
                    : `http://admin.jamitall.com/players/user/${row.original.user.id}`
                }
                target="_blank"
                rel="noreferrer"
              >
                {row.original.user.firstName} {row.original.user.lastName}
              </a>
            </p>

            {/* <p className="font-medium">
              {row.original.user.firstName} {row.original.user.lastName}
            </p> */}
          </div>
        );
      },
    },
    {
      accessorKey: "user.email",
      header: "Email",
    },
    {
      accessorKey: "session",
      header: () => <div className="text-left">League</div>,
      cell: ({ row }) => {
        return (
          <p className="font-medium">{row.original.session.league.name}</p>
        );
      },
    },
    {
      accessorKey: "createdAt",
      header: () => <div className="text-left">Added At</div>,
      cell: ({ row }) => {
        return (
          <p className="font-medium">
            {dayjs(row.original.createdAt).format("YYYY-MM-DD HH:mm")}
          </p>
        );
      },
    },
    {
      accessorKey: "productType.name",
      header: "Product Type",
    },
    {
      accessorKey: "status.name",
      header: "Status",
    },
    {
      accessorKey: "priority",
      header: "Priority",
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="outline-none">
                <MoreVerticalIcon className="transition-colors hover:bg-neutral-70 rounded-xl" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  className="hover:bg-primary-95"
                  onClick={() => {
                    setSelectedWaitList(row.original);
                    setPriorityModal(true);
                    setPriorityInput(row.original.priority);
                  }}
                >
                  Set Priority
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="hover:bg-primary-95"
                  onClick={() => {
                    setSelectedWaitList(row.original);
                    setStatusDialog(true);
                    setStatusIdInput(row.original.statusId);
                  }}
                >
                  Set Status
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  if (loading || productTypesLoading || waitListStatusesLoading) {
    return (
      <LoadingDialog
        open={loading || productTypesLoading || waitListStatusesLoading}
      />
    );
  }

  return (
    <main className="flex flex-col justify-between w-full pb-4">
      <LoadingDialog open={isLoading} />
      <Modal
        open={priorityModal}
        onClose={() => setPriorityModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="flex flex-col items-center justify-center gap-5">
            <Subtitle1>Set Priority</Subtitle1>
            <FormField
              type="number"
              label="Priority"
              initialValue={priorityInput ? priorityInput.toString() : "0"}
              inputChange={(e) => {
                setPriorityInput(e.target.value ? parseInt(e.target.value) : 0);
              }}
            ></FormField>
            <Button
              width="full"
              variant="primary"
              onClick={() => {
                if (selectedWaitList?.id) {
                  updateWaitlist({
                    variables: {
                      waitListId: selectedWaitList.id,
                      priority: priorityInput,
                    },
                  });
                }
              }}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Dialog
        open={statusDialog}
        onOpenChange={setStatusDialog}
      >
        <DialogContent>
          <DialogHeader>Select Status</DialogHeader>
          <div>
            <FormFieldSelect
              value={statusIdInput.toString()}
              inputChange={(value) => setStatusIdInput(+value)}
              label="Status"
              placeholder="Select Status"
            >
              {[{ id: 0, name: "Select Status" }, ...waitListStatusesList]}
            </FormFieldSelect>
          </div>
          <DialogFooter className="flex flex-row justify-start gap-2 sm:justify-start">
            <DialogClose>
              <Button
                variant="primary"
                onClick={() => {
                  if (selectedWaitList?.id) {
                    updateWaitlist({
                      variables: {
                        waitListId: selectedWaitList.id,
                        statusId: statusIdInput,
                      },
                    });
                  }
                }}
              >
                Confirm
              </Button>
            </DialogClose>
            <DialogClose>
              <Button variant="secondary">Close</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Headline1Variable>Waitlist</Headline1Variable>
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-5 gap-4">
          <FormFieldSelect
            inputChange={(value) => {
              setWaitListFilter((prevState: WaitListFilter) => {
                return {
                  ...prevState,
                  registrationBatchId: +value,
                };
              });
            }}
            label="Registration Batch"
            placeholder="Registration Batch"
            value={waitListFilter.registrationBatchId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...registrationBatches]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setWaitListFilter((prevState: WaitListFilter) => {
                return {
                  ...prevState,
                  regionId: +value,
                };
              });
            }}
            label="Region"
            placeholder="Region"
            value={waitListFilter.regionId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...selectedRegions]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setWaitListFilter((prevState: WaitListFilter) => {
                return {
                  ...prevState,
                  sportId: +value,
                };
              });
            }}
            label="Sport"
            placeholder="Sport"
            value={waitListFilter.sportId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...sports]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setWaitListFilter((prevState: WaitListFilter) => {
                return {
                  ...prevState,
                  leagueId: +value,
                };
              });
            }}
            label="League"
            placeholder="League"
            value={waitListFilter.leagueId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...leagues]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setWaitListFilter((prevState: WaitListFilter) => {
                return {
                  ...prevState,
                  dayOfWeek: +value,
                };
              });
            }}
            label="Day Of Week"
            placeholder="Day Of Week"
            value={waitListFilter.dayOfWeek?.toString() ?? "-1"}
          >
            {[{ id: "-1", name: "All" }, ...weekdaysArray]}
          </FormFieldSelect>
        </div>
        <div className="grid grid-cols-5 gap-4">
          <FormFieldSelect
            inputChange={(value) => {
              setWaitListFilter((prevState: WaitListFilter) => {
                return {
                  ...prevState,
                  productTypeId: +value,
                };
              });
            }}
            label="Product Type"
            placeholder="ProductType"
            value={waitListFilter.productTypeId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...productTypesList]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setWaitListFilter((prevState: WaitListFilter) => {
                return {
                  ...prevState,
                  statusId: +value,
                };
              });
            }}
            label="Waitlist Status"
            placeholder="Waitlist Status"
            value={waitListFilter.statusId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...waitListStatusesList]}
          </FormFieldSelect>
        </div>
        <div className="flex flex-col gap-1">
          <Subtitle1>Start Date Range</Subtitle1>
          <div className="grid items-end grid-cols-4 gap-4 mb-4">
            <FormFieldDate
              label="Start*"
              initialValue={dayjs(waitListFilter.startDate).toDate()}
              dateChange={(date) => {
                setWaitListFilter((prevState: WaitListFilter) => {
                  return {
                    ...prevState,
                    startDate: dayjs(date).format("YYYY-MM-DD"),
                  };
                });
              }}
            />
            <FormFieldDate
              label="End"
              initialValue={
                waitListFilter.endDate
                  ? dayjs(waitListFilter.endDate).toDate()
                  : undefined
              }
              dateChange={(date) => {
                setWaitListFilter((prevState: WaitListFilter) => {
                  return {
                    ...prevState,
                    endDate: dayjs(date).format("YYYY-MM-DD"),
                  };
                });
              }}
            />
            <div className="flex flex-row col-span-2 gap-4">
              <Button
                variant="secondary"
                className="h-12"
                onClick={() => {
                  setWaitListFilter((prevState: WaitListFilter) => {
                    return {
                      ...prevState,
                      startDate: initialWaitListFilter.startDate,
                      endDate: null,
                    };
                  });
                }}
              >
                Reset Dates
              </Button>
              <Button
                variant="primary"
                className="h-12"
                onClick={() => {
                  setWaitListFilter(initialWaitListFilter);
                }}
              >
                Reset All Filters
              </Button>
            </div>
          </div>
        </div>
      </div>
      {waitListData.length > 0 && (
        <div className="flex flex-col gap-4">
          <DataTable
            data={waitListData}
            columns={waitListColumns}
          />
          {pagination.pageCount && (
            <>
              <div className="my-auto text-xs text-disabled">
                Page {pagination.page + 1} of{" "}
                {Math.ceil(pagination.pageCount / pagination.pageSize)}
              </div>
              <div className="flex flex-row">
                <Pagination
                  page={pagination.page + 1}
                  count={Math.ceil(pagination.pageCount / pagination.pageSize)}
                  onChange={(event: any, value: number) => {
                    setPagination((prevState: PaginationType) => {
                      return {
                        ...prevState,
                        page: value - 1,
                      };
                    });
                  }}
                />
                <Select
                  value={`${pagination.pageSize.toString()}`}
                  onValueChange={(value) => {
                    setPagination((prevState: PaginationType) => {
                      return {
                        ...prevState,
                        page: Math.floor(
                          (prevState.page * prevState.pageSize) / Number(value)
                        ),
                        pageSize: Number(value),
                      };
                    });
                  }}
                >
                  <SelectTrigger className="h-8 w-[70px]">
                    <SelectValue placeholder={pagination.pageSize.toString()} />
                  </SelectTrigger>
                  <SelectContent side="top">
                    {[5, 10, 25, 50, 100].map((pageSize) => (
                      <SelectItem
                        key={pageSize}
                        value={`${pageSize}`}
                        className="hover:bg-primary-95"
                      >
                        {pageSize}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </>
          )}
        </div>
      )}
    </main>
  );
};

export default WaitList;
