import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { LoadingMaterialUI, SwitchButton } from "../UI";
import classes from "./CreateSportsFormat.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import {
  getGenderFormat,
  getLeagueType,
  getOfficiatedStatus,
  getRules,
  getSports,
  getTieBrakePolicies,
  getVenueSizes,
  getVenueTypes,
} from "../../app/venueMasterSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Rule } from "../../types/types";
import { HighlightOffRounded } from "@mui/icons-material";
import { getStaffType } from "../../app/shiftSlice";
import FormField from "../UI/FormField/FormField";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import Card from "../UI/Card/Card";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import Button from "../UI/Button/Button";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import {
  CreateSportFormatInput,
  SportFormatCreateMutationVariables,
  SportFormatQuery,
  useScoringFormatsQuery,
  useSportFormatCreateMutation,
  useSportFormatQuery,
  useSportFormatUpdateMutation,
  useVenueSizeByTypeIdQuery,
} from "../../../src/generated/graphql";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { z, ZodFormattedError } from "zod";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../app/globalSlice";
import { Trash2 } from "lucide-react";
import Alert from "../UI/Alerts/Alert";

const StaffRequirementSchema = z.object({
  staffTypeId: z.number().min(1, {
    message: "Staff type is required",
  }),
  count: z.number().min(1, { message: "Count must be at least 1" }),
});

const SportFormatSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(1, { message: "Name is required" }),
  sportId: z.number().min(1, { message: "Sport is required" }),
  genderFormatId: z.number().min(1, { message: "Gender format is required" }),
  regionId: z.number().min(1, { message: "Region is required" }),
  officiatedStatusId: z
    .number()
    .min(1, { message: "Officiated status is required" }),
  leagueTypeId: z.number().min(1, { message: "League type is required" }),
  venueTypeId: z.number().min(1, { message: "Venue type is required" }),
  venueSizeId: z.number().min(1, { message: "Venue size is required" }),
  abbreviation: z.string().nonempty({ message: "Abbreviation is required" }),
  ruleId: z.number().optional().nullable(),
  scoringFormatId: z.number().optional().nullable(),
  weeksOfPlayOffs: z
    .number()
    .min(0, { message: "Weeks of playoffs must be non-negative" }),
  tieBreakPolicyId: z
    .number()
    .min(1, { message: "Tie break policy is required" }),
  defaultEmail: z.number().int().min(0).max(1),
  peopleOnField: z
    .number()
    .min(1, { message: "Players on field must be at least 1" }),
  minMen: z.number().min(0, { message: "Min men cannot be negative" }),
  minWomen: z.number().min(0, { message: "Min women cannot be negative" }),
  maxRosterSizeTeam: z
    .number()
    .min(0, { message: "Max roster cannot be negative" }),
  maxRosterSizeIndy: z
    .number()
    .min(0, { message: "Max Indy roster cannot be negative" }),
  minMenIndy: z.number().min(0, { message: "Min men Indy cannot be negative" }),
  minWomenIndy: z
    .number()
    .min(0, { message: "Min women Indy cannot be negative" }),
  staffRequired: z.array(StaffRequirementSchema),
});

type SportFormatForm = z.infer<typeof SportFormatSchema>;

const CreateSportFormat: React.FC = () => {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const isEditSportFormat =
    params.id !== undefined && !isNaN(+params.id) ? +params.id : undefined;

  const {
    selectedRegions,
    leagueTypes,
    tieBreakPolicies,
    sports,
    genderFormat,
    venueTypes,
    officiatedStatuses,
    isLoading,
    rules,
  }: any = useSelector((state: RootState) => state.venueMaster);

  const { staffTypeOptions }: any = useSelector(
    (state: RootState) => state.shift
  );
  const navigate = useNavigate();
  const rootPath =
    process.env.NODE_ENV === "production" ? process.env.REACT_APP_URL : "";

  const initialSportFormat: SportFormatForm = {
    name: "",
    sportId: 0,
    genderFormatId: 0,
    regionId: 0,
    officiatedStatusId: 0,
    leagueTypeId: 0,
    venueTypeId: 0,
    venueSizeId: 0,
    abbreviation: "",
    ruleId: 0,
    scoringFormatId: 1,
    weeksOfPlayOffs: 0,
    tieBreakPolicyId: 1,
    defaultEmail: 0,
    peopleOnField: 0,
    minMen: 0,
    minWomen: 0,
    maxRosterSizeTeam: 0,
    maxRosterSizeIndy: 0,
    minMenIndy: 0,
    minWomenIndy: 0,
    staffRequired: [],
  };

  const [sportFormat, setSportFormat] =
    useState<SportFormatForm>(initialSportFormat);
  const [updateStaffRequired, setUpdateStaffRequired] =
    useState<boolean>(false);
  const [zodErrors, setZodErrors] = useState<
    ZodFormattedError<SportFormatForm, string>
  >({ _errors: [] });

  useEffect(() => {
    dispatch(getVenueTypes(""));
    dispatch(getLeagueType(""));
    dispatch(getTieBrakePolicies(""));
    dispatch(getGenderFormat(""));
    dispatch(getSports(""));
    dispatch(getOfficiatedStatus(""));
    dispatch(getRules(""));
    dispatch(getStaffType());
  }, []);
  const { data: scoringFormats } = useScoringFormatsQuery({});

  const { data: sportFormatById } = useSportFormatQuery({
    variables: {
      sportFormatId: +isEditSportFormat!,
    },
    skip: isEditSportFormat === undefined,
    onCompleted(data) {
      const { __typename, ...otherFields } = data.sportFormat;
      setSportFormat(otherFields);
    },
  });

  const { loading: loadingVenueSizes, data: venueSizeData } =
    useVenueSizeByTypeIdQuery({
      variables: {
        venueTypeId: sportFormat.venueTypeId,
      },
    });

  const [createSportFormat, { loading: loadingCreate }] =
    useSportFormatCreateMutation();
  const [updateSportFormat, { loading: loadingUpdate }] =
    useSportFormatUpdateMutation();

  const handleSubmit = () => {
    const result = SportFormatSchema.safeParse(sportFormat);
    console.log(result);
    if (!result.success) {
      setZodErrors(result.error.format());
      dispatch(displayAlertWarning("Issue with form"));
      return;
    }

    const { id, staffRequired, ...sportFormatFields } = sportFormat;

    if (id === undefined) {
      createSportFormat({
        variables: {
          sportFormatArgs: {
            sportFormatInput: sportFormatFields,
            staffRequiredInput: staffRequired,
          },
        },
        onCompleted(data, clientOptions) {
          if (data.sportFormatCreate.success) {
            dispatch(displayAlertSuccess(data.sportFormatCreate.message));
            navigate(`${rootPath}/ops/sport-format`);
          } else {
            dispatch(displayAlertError(data.sportFormatCreate.message));
          }
        },
        onError(error, clientOptions) {
          dispatch(displayAlertError(error.message));
        },
      });
    } else {
      updateSportFormat({
        variables: {
          sportFormatArgs: {
            id: id,
            sportFormatInput: sportFormatFields,
            staffRequiredInput: staffRequired,
            updateStaff: updateStaffRequired,
          },
        },
        onCompleted(data, clientOptions) {
          if (data.sportFormatUpdate.success) {
            dispatch(displayAlertSuccess(data.sportFormatUpdate.message));
            navigate(`${rootPath}/ops/sport-format`);
          } else {
            dispatch(displayAlertError(data.sportFormatUpdate.message));
          }
        },
        onError(error, clientOptions) {
          dispatch(displayAlertError(error.message));
        },
      });
    }
  };
  const renderErrors = (errors: any, parentKey = ""): JSX.Element[] => {
    return Object.keys(errors).flatMap((key) => {
      const error = errors[key];
      const displayKey = key === "_errors" ? parentKey.split(".").pop() : key; // Get the last segment of the path

      if (key === "_errors" && Array.isArray(error) && error.length > 0) {
        // Display the error message with only the final field name
        return error.map((message) => (
          <Alert
            variant="error"
            content={`${displayKey}: ${message}`}
            size="large"
            persist={true}
            key={`${parentKey}-error`}
          />
        ));
      } else if (typeof error === "object" && error !== null) {
        // Recursively process nested fields
        return renderErrors(error, `${parentKey}.${key}`);
      }
      return [];
    });
  };

  useEffect(() => {
    console.log(sportFormat);
  }, [sportFormat]);

  if (loadingCreate || loadingUpdate || isLoading) return <LoadingMaterialUI />;

  const pathsBreadcrumbs = [
    { name: "Sport Format", url: "/ops/sport-format" },
    { name: "Sport Format Details", url: "/ops/sport-format/new" },
  ];

  return (
    <main>
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/vendors"
      />
      <div className="flex flex-row items-center justify-between w-full mt-6">
        <Headline1Variable>
          {isEditSportFormat ? "Edit Sport format" : "Create New Sport Format"}
        </Headline1Variable>
        <Button
          variant="primary"
          className="h-fit"
          onClick={() => handleSubmit()}
        >
          {isEditSportFormat ? (
            <span> Edit Sport Format</span>
          ) : (
            <span> Create Sport Format</span>
          )}
        </Button>
      </div>
      {renderErrors(zodErrors)}
      <Card className="mt-6">
        <Subtitle1>Sport Form Details</Subtitle1>
        <div className="flex flex-row w-full gap-4 mt-6">
          <FormField
            initialValue={sportFormat.name}
            error={zodErrors.name !== undefined}
            inputChange={(e) => {
              setSportFormat((prevState) => ({
                ...prevState,
                name: e.target.value,
              }));
            }}
            name="name"
            label="Name"
          ></FormField>
          <FormFieldSelect
            name="sportId"
            value={sportFormat.sportId ? sportFormat.sportId.toString() : "0"}
            error={zodErrors.sportId !== undefined}
            inputChange={(value) => {
              setSportFormat((prevState) => ({
                ...prevState,
                sportId: +value,
              }));
            }}
            label="Sport"
            placeholder="Select Sport"
          >
            {[...[{ id: "0", name: "Select Sport" }], ...sports]}
          </FormFieldSelect>
          <FormFieldSelect
            name="regionId"
            value={sportFormat.regionId ? sportFormat.regionId.toString() : "0"}
            error={zodErrors.regionId !== undefined}
            inputChange={(value) => {
              setSportFormat((prevState) => ({
                ...prevState,
                regionId: +value,
              }));
            }}
            label="Region"
            placeholder="Select Region"
          >
            {[...[{ id: "0", name: "Select Region" }], ...selectedRegions]}
          </FormFieldSelect>
          <FormFieldSelect
            name="genderFormatId"
            value={
              sportFormat.genderFormatId
                ? sportFormat.genderFormatId.toString()
                : "0"
            }
            error={zodErrors.genderFormatId !== undefined}
            inputChange={(value) => {
              setSportFormat((prevState) => ({
                ...prevState,
                genderFormatId: +value,
              }));
            }}
            label="Gender Format"
            placeholder="Select Gender Format"
          >
            {[...[{ id: "0", name: "Select Gender Format" }], ...genderFormat]}
          </FormFieldSelect>
          <FormField
            initialValue={sportFormat.abbreviation}
            error={zodErrors.abbreviation !== undefined}
            inputChange={(e) => {
              setSportFormat((prevState) => ({
                ...prevState,
                abbreviation: e.target.value,
              }));
            }}
            name="abbreviation"
            label="Abbreviation"
          ></FormField>
        </div>
        <div className="flex flex-row w-full gap-4 mt-6">
          <FormFieldSelect
            name="scoringFormatId"
            value={
              sportFormat.scoringFormatId
                ? sportFormat.scoringFormatId.toString()
                : "0"
            }
            error={false}
            inputChange={(value) => {
              setSportFormat((prevState) => ({
                ...prevState,
                scoringFormatId: +value,
              }));
            }}
            label="Scoring Format"
            placeholder="Select Scoring Format"
          >
            {[
              { id: "0", name: "Select Scoring format" },
              ...(scoringFormats ? scoringFormats.scoringFormats : []),
            ]}
          </FormFieldSelect>
          <FormFieldSelect
            name="officiatedStatusId"
            value={
              sportFormat.officiatedStatusId
                ? sportFormat.officiatedStatusId.toString()
                : "0"
            }
            error={zodErrors.officiatedStatusId !== undefined}
            inputChange={(value) => {
              setSportFormat((prevState) => ({
                ...prevState,
                officiatedStatusId: +value,
              }));
            }}
            label="Officiated Status"
            placeholder="Select Officiated Status"
          >
            {[
              ...[{ id: "0", name: "Select Officiated Status" }],
              ...officiatedStatuses,
            ]}
          </FormFieldSelect>
          <FormFieldSelect
            name="leagueTypeId"
            value={
              sportFormat.leagueTypeId
                ? sportFormat.leagueTypeId.toString()
                : "0"
            }
            error={zodErrors.leagueTypeId !== undefined}
            inputChange={(value) => {
              setSportFormat((prevState) => ({
                ...prevState,
                leagueTypeId: +value,
              }));
            }}
            label="League Type"
            placeholder="Select League Type"
          >
            {[...[{ id: "0", name: "Select League Type" }], ...leagueTypes]}
          </FormFieldSelect>
          <FormFieldSelect
            value={sportFormat.venueTypeId.toString()}
            inputChange={(value) => {
              if (value === "0") {
                setSportFormat((prevState) => ({
                  ...prevState,
                  venueSizeId: 0,
                }));
              }
              setSportFormat((prevState) => ({
                ...prevState,
                venueTypeId: parseInt(value),
                venueSizeId: 0,
              }));
            }}
            name="venueType"
            label="Venue Type"
            placeholder="Venue Type"
            error={zodErrors.venueTypeId !== undefined}
          >
            {[{ id: 0, name: "Select Venue Type" }, ...venueTypes]}
          </FormFieldSelect>
          <FormFieldSelect
            name="venueSizeId"
            value={
              sportFormat.venueSizeId ? sportFormat.venueSizeId.toString() : "0"
            }
            error={zodErrors.venueSizeId !== undefined}
            loading={loadingVenueSizes}
            inputChange={(value) => {
              setSportFormat((prevState) => ({
                ...prevState,
                venueSizeId: +value,
              }));
            }}
            label="Venue Size"
            placeholder="Venue Size"
          >
            {[
              ...[
                {
                  id: 0,
                  name:
                    venueSizeData === undefined ||
                    venueSizeData.venueSizeByTypeId.length === 0
                      ? "Select Venue Type First"
                      : "Select Venue Size",
                },
              ],
              ...(venueSizeData !== undefined
                ? venueSizeData.venueSizeByTypeId.map((venueSize) => {
                    return {
                      id: venueSize.id,
                      name: venueSize.name,
                    };
                  })
                : []),
            ]}
          </FormFieldSelect>
        </div>
        <div className="flex flex-row w-full gap-4 mt-6">
          <FormField
            type="number"
            initialValue={sportFormat.weeksOfPlayOffs?.toString()}
            error={zodErrors.weeksOfPlayOffs !== undefined}
            inputChange={(e) => {
              setSportFormat((prevState) => ({
                ...prevState,
                weeksOfPlayOffs: +e.target.value,
              }));
            }}
            name="weeksOfPlayOffs"
            label="Weeks of Playoffs"
          ></FormField>
          <FormFieldSelect
            name="tieBreakPolicyId"
            value={
              sportFormat.tieBreakPolicyId
                ? sportFormat.tieBreakPolicyId.toString()
                : "0"
            }
            error={zodErrors.tieBreakPolicyId !== undefined}
            inputChange={(value) => {
              setSportFormat((prevState) => ({
                ...prevState,
                tieBreakPolicyId: +value,
              }));
            }}
            label="Tie Break Policy"
            placeholder="Select Tie Break Policy"
          >
            {[
              ...[{ id: "0", name: "Select Tie Break Policy" }],
              ...tieBreakPolicies,
            ]}
          </FormFieldSelect>
          <div className="w-1/2">
            <label className={`px-3 text-xs font-medium text-black`}>
              Default Email
            </label>
            <SwitchButton
              labelStart="NO"
              labelEnd="YES"
              height="30"
              width="50"
              checked={sportFormat.defaultEmail === 1}
              handleChange={(value) => {
                setSportFormat((prevState) => ({
                  ...prevState,
                  defaultEmail: value.target.checked ? 1 : 0,
                }));
              }}
            />
          </div>
        </div>
        <div className="flex flex-row w-1/4 gap-4 mt-6">
          <FormFieldSelect
            name="ruleId"
            value={
              sportFormat.ruleId?.toString() || "0"
                ? sportFormat.ruleId?.toString() || "0".toString()
                : "0"
            }
            error={zodErrors.ruleId !== undefined}
            inputChange={(value) => {
              setSportFormat((prevState) => ({
                ...prevState,
                ruleId: value,
              }));
            }}
            label="Rule"
            placeholder="Select Rule"
          >
            {[
              ...[
                {
                  id: "0",
                  name:
                    rules.filter((rule: Rule) => {
                      return (
                        (rule.sportId == sportFormat.sportId &&
                          rule.regionId == sportFormat.regionId) ||
                        rule.regionId == null
                      );
                    }).length > 0
                      ? "Select Rule"
                      : `No rule ${
                          sportFormat.sportId != 0 && sportFormat.regionId != 0
                            ? " for sport and region"
                            : "(select sport and region)"
                        }`,
                },
              ],
              ...rules.filter((rule: Rule) => {
                return (
                  (rule.sportId == sportFormat.sportId &&
                    rule.regionId == sportFormat.regionId) ||
                  rule.regionId == null
                );
              }),
            ]}
          </FormFieldSelect>
        </div>
      </Card>
      <Card className="mt-6">
        <Subtitle1>Roster Size</Subtitle1>
        <div className="flex flex-row w-full gap-4 mt-6">
          <FormField
            type="number"
            initialValue={sportFormat.peopleOnField.toString()}
            error={zodErrors.peopleOnField !== undefined}
            inputChange={(e) =>
              setSportFormat((prevState) => ({
                ...prevState,
                peopleOnField: +e.target.value,
              }))
            }
            name="peopleOnField"
            label="Players on Field"
          ></FormField>
          <FormField
            type="number"
            initialValue={sportFormat.minMen.toString()}
            error={zodErrors.minMen !== undefined}
            inputChange={(e) =>
              setSportFormat((prevState) => ({
                ...prevState,
                minMen: +e.target.value,
              }))
            }
            name="minMenTeam"
            label="Min Men Team"
          ></FormField>
          <FormField
            type="number"
            initialValue={sportFormat.minWomen.toString()}
            error={zodErrors.minWomen !== undefined}
            inputChange={(e) =>
              setSportFormat((prevState) => ({
                ...prevState,
                minWomen: +e.target.value,
              }))
            }
            name="minWomenTeam"
            label="Min Women Team"
          ></FormField>
          <FormField
            type="number"
            initialValue={sportFormat.maxRosterSizeTeam.toString()}
            error={zodErrors.maxRosterSizeTeam !== undefined}
            inputChange={(e) =>
              setSportFormat((prevState) => ({
                ...prevState,
                maxRosterSizeTeam: +e.target.value,
              }))
            }
            name="maxRosterSizeTeam"
            label="Max Roster Size"
          ></FormField>
        </div>
      </Card>
      <Card className="mt-6">
        <Subtitle1>Indy team Roster</Subtitle1>
        <div className="flex flex-row w-full gap-4 mt-6">
          <FormField
            type="number"
            initialValue={sportFormat.maxRosterSizeIndy.toString()}
            error={zodErrors.maxRosterSizeIndy !== undefined}
            inputChange={(e) =>
              setSportFormat((prevState) => ({
                ...prevState,
                maxRosterSizeIndy: +e.target.value,
              }))
            }
            name="maxRosterSizeIndy"
            label="Max Roster Size"
          ></FormField>
          <FormField
            type="number"
            initialValue={sportFormat.minMenIndy.toString()}
            error={zodErrors.minMenIndy !== undefined}
            inputChange={(e) =>
              setSportFormat((prevState) => ({
                ...prevState,
                minMenIndy: +e.target.value,
              }))
            }
            name="minMenIndy"
            label="Min Men FA"
          ></FormField>
          <FormField
            type="number"
            initialValue={sportFormat.minWomenIndy.toString()}
            error={zodErrors.minWomenIndy !== undefined}
            inputChange={(e) =>
              setSportFormat((prevState) => ({
                ...prevState,
                minWomenIndy: +e.target.value,
              }))
            }
            name="minWomenIndy"
            label="Min Women FA"
          ></FormField>
        </div>
      </Card>
      <Card className="mt-6">
        <Subtitle1>Staff Requirements</Subtitle1>
        <div className="flex flex-col w-1/2 gap-4 mt-6">
          <div className="flex flex-col gap-2">
            {sportFormat.staffRequired.map((staffRequired, i) => {
              return (
                <div className="flex items-end gap-3">
                  <div className="min-w-80 max-80">
                    <FormFieldSelect
                      name="staffType"
                      error={zodErrors.staffRequired !== undefined}
                      value={staffRequired.staffTypeId.toString()}
                      inputChange={(value) => {
                        setSportFormat((prevState) => {
                          const updatedStaffRequired = [
                            ...prevState.staffRequired,
                          ];
                          updatedStaffRequired[i].staffTypeId = +value;
                          return {
                            ...prevState,
                            staffRequired: updatedStaffRequired,
                          };
                        });
                        setUpdateStaffRequired(true);
                      }}
                      label="Staff Type"
                      placeholder="Select Staff Type"
                    >
                      {[
                        ...[{ id: "0", name: "Select Staff Type" }],
                        ...staffTypeOptions,
                      ]}
                    </FormFieldSelect>
                  </div>
                  <FormField
                    type="number"
                    error={zodErrors.staffRequired !== undefined}
                    initialValue={staffRequired.count.toString()}
                    inputChange={(e) => {
                      const updatedStaffRequired = [
                        ...sportFormat.staffRequired,
                      ];
                      updatedStaffRequired[i].count = +e.target.value;
                      setSportFormat({
                        ...sportFormat,
                        staffRequired: updatedStaffRequired,
                      });
                      setUpdateStaffRequired(true);
                    }}
                    name="count"
                    label="Count"
                  />
                  <Button
                    variant="negative"
                    className="h-min"
                    onClick={() => {
                      const updatedStaffRequired =
                        sportFormat.staffRequired.filter(
                          (_, index) => index !== i
                        );
                      setSportFormat({
                        ...sportFormat,
                        staffRequired: updatedStaffRequired,
                      });
                      setUpdateStaffRequired(true);
                    }}
                  >
                    <Trash2 />
                  </Button>
                </div>
              );
            })}
            {sportFormat.staffRequired.length === 0 && (
              <Subtitle1>No Staff Required.</Subtitle1>
            )}
          </div>

          <Button
            variant="secondary"
            className="w-fit whitespace-nowrap"
            onClick={() => {
              setSportFormat((prevState) => ({
                ...prevState,
                staffRequired: [
                  ...prevState.staffRequired,
                  { staffTypeId: 0, count: 0 },
                ],
              }));
              setUpdateStaffRequired(true);
            }}
          >
            + Add Staff Requirement
          </Button>
        </div>
      </Card>
      <div className="mt-5 text-right">
        <Button
          variant="primary"
          className="h-fit"
          onClick={() => handleSubmit()}
        >
          {isEditSportFormat ? (
            <span> Edit Sport Format</span>
          ) : (
            <span> Create Sport Format</span>
          )}
        </Button>
      </div>
    </main>
  );
};

export default CreateSportFormat;
