import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { getPrivacySettings, updateUserById } from "../../app/cxSlice";
import { getAllPermissions } from "../../app/authSlice";
import Card from "../UI/Card/Card";
import FormField from "../UI/FormField/FormField";
import CheckBox from "../UI/Checkbox/Checkbox";
import FormFieldDateSelect from "../UI/FormField/FormFieldDate/FormFieldDateSelect";
import Button from "../UI/Button/Button";
import {
  Pagination,
  Registration,
  ShoppingCart,
  User,
} from "../../types/types";
import { LoadingMaterialUI } from "../UI";
import Disclaimer from "../UI/Alerts/Disclaimer";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import moment from "moment";
import TabGroup from "../UI/Tab/TabGroup/TabGroup";
import { Column } from "react-table";
import dayjs from "dayjs";
import BaseTable from "../UI/Table/Table";
import { DayOfWeek } from "../../app/venueMasterSlice";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Button as MuiButton } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import Headline2Variable from "../UI/Text/Headline/Headline2Variable";
import { Dialog, DialogContent, DialogHeader } from "../UI/shadcn/dialog";
import utc from "dayjs/plugin/utc";
import { emailButton } from "../Marketing/Email/EmailButton";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import Notification from "./Notification";
import {
  CreditsByUserIdQuery,
  GetAllShoppingCartsByUserIdQuery,
  ListAllOperations,
  RegistrationByUserIdQuery,
  ShirtSizeAllQuery,
  TeamInvitationsByTeamIdQuery,
  TeamPlayerByUserIdQuery,
  useCreateTeamInvitationsMutation,
  useCreditsByUserIdLazyQuery,
  useGetAllShoppingCartsByUserIdLazyQuery,
  useGetEmailLogsByUserLazyQuery,
  useGetEmailLogsMailgunByUserLazyQuery,
  UserByIdGetQuery,
  useRegistrationByUserIdLazyQuery,
  UserPermission,
  useShirtSizeAllQuery,
  useTeamInvitationsByTeamIdLazyQuery,
  useTeamPlayerByUserIdLazyQuery,
  useUpdateUserMutation,
  useUserByIdGetQuery,
} from "../../generated/graphql";
import Body1 from "../UI/Text/Body/Body1";
import Body2 from "../UI/Text/Body/Body2";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import Subtitle2 from "../UI/Text/Subtitle/Subtitle2";
import { DataTable } from "../UI/Table/ShadcnTable";
import { ColumnDef } from "@tanstack/react-table";
import AddIcon from "@mui/icons-material/Add";
import { client } from "../../graphql";
import { LoaderCircle, MailSearchIcon } from "lucide-react";
import { z } from "zod";
import { numberToMoney } from "../../utils/financialHelpers";
import WaitlistSessionsDialog from "../UI/Dialog/WaitlistSessionsDialog";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";

dayjs.extend(utc);

const TeamInvitationEmailSchema = z
  .string()
  .refine((val) => emailRegex.test(val), {
    message: "Invalid email format",
  });

type TeamInvitationEmailType = z.infer<typeof TeamInvitationEmailSchema>;

const TeamInvitationEmailsSchema = z
  .array(TeamInvitationEmailSchema)
  .min(1, { message: "Please enter at least one email" });

type TeamInvitationEmailsType = z.infer<typeof TeamInvitationEmailsSchema>;

const initialUser: UserByIdGetQuery["userByIdGet"] = {
  id: "",
  email: "",
  firstName: "",
  lastName: "",
  genderIdentity: {
    id: 0,
    name: "",
  },
  phoneNumber: "",
  isStaff: false,
  isAdmin: false,
  isSuperAdmin: false,
  marketingEmail: false,
  permission: [],
  privacyGender: 2,
  privacyPhoneNumber: 2,
  regionId: 0,
  shirtSizeId: 0,
  scoreReminderEmail: false,
  gameReminderEmail: false,
  registrationOpeningEmail: false,
  sportSpecificEmail: false,
};

type TabGroupType =
  | "Info"
  | "Cart"
  | "Registration"
  | "Team"
  | "Permission"
  | "Email Logs"
  | "Notification"
  | "Credit";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Player: React.FC = () => {
  const pathsBreadcrumbs = [
    { name: "Player Search", url: "/players/users" },
    { name: "Player Info", url: "" },
  ];

  const pageSize = 10;
  const pageSizeDropdown = 20;
  const paginationDefault: Pagination = {
    pageCount: 0,
    pageSize: pageSize,
    page: 0,
  };
  const paginationDropdownDefault: Pagination = {
    pageCount: 0,
    pageSize: pageSizeDropdown,
    page: 0,
  };

  const params = useParams();
  const isEditPlayer = params.id;
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, privacySettings }: any = useSelector(
    (state: RootState) => state.cx
  );
  const { selectedRegions, regions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );
  const { permissions, user: admin }: any = useSelector(
    (state: RootState) => state.auth
  );
  const [user, setUser] =
    useState<UserByIdGetQuery["userByIdGet"]>(initialUser);
  const [tabGroupValue, setTabGroupValue] = useState<TabGroupType>("Info");
  const [shoppingCarts, setShoppingCarts] = useState<
    GetAllShoppingCartsByUserIdQuery["getAllShoppingCartsByUserId"]
  >([]);
  const [registrations, setRegistrations] = useState<
    RegistrationByUserIdQuery["registrationByUserId"]
  >([]);
  const [teams, setTeams] = useState<
    TeamPlayerByUserIdQuery["teamPlayerByUserId"]
  >([]);
  const [teamInvitations, setTeamInvitations] = useState<
    TeamInvitationsByTeamIdQuery["teamInvitationsByTeamId"]
  >([]);
  const [credits, setCredits] = useState<
    CreditsByUserIdQuery["creditsByUserId"]
  >([]);
  const [shirtSizes, setShirtSizes] = useState<
    ShirtSizeAllQuery["shirtSizeAll"]
  >([]);
  const [teamInvitationEmail, setTeamInvitationEmail] =
    useState<TeamInvitationEmailType>("");
  const [teamInvitationEmails, setTeamInvitationEmails] =
    useState<TeamInvitationEmailsType>([]);
  const [emailLogs, setEmailLogs] = useState<any>({});
  const [paginationMailgun, setPaginationMailgun] =
    useState<Pagination>(paginationDefault);
  const [paginationEmail, setPaginationEmail] = useState<Pagination>(
    paginationDropdownDefault
  );
  const [selectedEmail, setSelectedEmail] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [openTeamInvitationDialog, setOpenTeamInvitationDialog] =
    useState<boolean>(false);
  const [teamInvitationDialogDetails, setTeamInvitationDialogDetails] =
    useState<{ teamName: string; captainName: string; teamId: number }>({
      teamName: "",
      captainName: "",
      teamId: 0,
    });
  const [openWaitlistDialog, setOpenWaitlistDialog] = useState<boolean>(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [UpdateUser, { data, loading, error }] = useUpdateUserMutation();
  const [
    getAllCartsByUserId,
    { data: cartByUserId, loading: cartLoading, error: cartError },
  ] = useGetAllShoppingCartsByUserIdLazyQuery({
    fetchPolicy: "no-cache",
  });
  const [
    getRegistrationsByUserId,
    {
      data: registrationsByUserId,
      loading: registrationLoading,
      error: registrationError,
    },
  ] = useRegistrationByUserIdLazyQuery({
    fetchPolicy: "no-cache",
  });
  const [
    getTeamPlayerByUserId,
    {
      data: teamPlayerByUserId,
      loading: teamPlayerLoading,
      error: teamPlayerError,
    },
  ] = useTeamPlayerByUserIdLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [getTeamInvitationsByTeamId, { loading: teamInvitationsLoading }] =
    useTeamInvitationsByTeamIdLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setTeamInvitations(data.teamInvitationsByTeamId);
        setTeamInvitationEmails([]);
        setTeamInvitationEmail("");
      },
      notifyOnNetworkStatusChange: true,
    });

  const [
    getEmailLogsByUser,
    { data: emailLogsByUser, loading: emailLogsLoading, error: emailLogsError },
  ] = useGetEmailLogsByUserLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [
    getCreditsByUserId,
    { data: creditsByUserId, loading: creditsLoading, error: creditsError },
  ] = useCreditsByUserIdLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCredits(data.creditsByUserId);
    },
  });

  const { data: dataShirtSize, loading: loadingShirtSize } =
    useShirtSizeAllQuery({
      onCompleted: (data) => {
        setShirtSizes(data.shirtSizeAll);
      },
    });

  const [
    getEmailLogsMailgunByUser,
    {
      data: emailLogsMailgunByUser,
      loading: emailLogsMailgunLoading,
      error: emailLogsMailgunError,
    },
  ] = useGetEmailLogsMailgunByUserLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [CreateTeamInvitations, { loading: loadingTeamInvitationCreate }] =
    useCreateTeamInvitationsMutation();

  const retrieveExistingCart = async (userId: string) => {
    await getAllCartsByUserId({
      variables: { userId: userId },
      onCompleted: (data) => {
        if (!data.getAllShoppingCartsByUserId) {
          setShoppingCarts([]);
        } else {
          setShoppingCarts(data.getAllShoppingCartsByUserId);
        }
      },
    });
  };

  const retrieveRegistrations = async (userId: string) => {
    await getRegistrationsByUserId({
      variables: { userId: userId },
      onCompleted: (data) => {
        if (!data.registrationByUserId) {
          setRegistrations([]);
        } else {
          setRegistrations(data.registrationByUserId);
        }
      },
    });
  };

  const retrieveTeams = async (userId: string) => {
    await getTeamPlayerByUserId({
      variables: { userId: userId },
      onCompleted: (data) => {
        if (!data.teamPlayerByUserId) {
          setTeams([]);
        } else {
          setTeams(data.teamPlayerByUserId);
        }
      },
    });
  };

  const retrieveEmailLogs = async (email: string) => {
    getEmailLogsByUser({
      variables: { email: email, pageSize: pageSizeDropdown, page: 0 },
    });
    getEmailLogsMailgunByUser({ variables: { email: email } });
  };

  const handleChangePaginationMailgun = (value: number) => {
    setPaginationMailgun({
      pageCount: paginationMailgun?.pageCount,
      pageSize: paginationMailgun?.pageSize,
      page: value - 1,
    });
  };
  const handleChangePaginationEmail = (value: number) => {
    setPaginationEmail({
      pageCount: paginationEmail?.pageCount,
      pageSize: paginationEmail?.pageSize,
      page: value - 1,
    });
  };

  const handleCloseDialog = () => {
    setOpenModal(false);
  };

  const handlePreviewEmail = async (body: string) => {
    setOpenModal(true);
    setSelectedEmail({ body: body });
  };

  async function handleSendTeamInvitations() {
    let result = TeamInvitationEmailsSchema.safeParse(teamInvitationEmails);
    if (!result.success) {
      result.error.errors.forEach((error) => {
        dispatch(displayAlertError(error.message));
      });
      return;
    }
    await CreateTeamInvitations({
      variables: {
        createTeamInvitationInput: {
          emails: teamInvitationEmails,
          teamId: teamInvitationDialogDetails.teamId,
        },
      },
      onCompleted: async (data) => {
        if (data.createTeamInvitation.success) {
          dispatch(displayAlertSuccess(data.createTeamInvitation.message));
        } else {
          dispatch(displayAlertError(data.createTeamInvitation.message));
        }
        await client.refetchQueries({
          include: [ListAllOperations.Query.TeamInvitationsByTeamId],
        });
      },
    });
  }

  const CART_COLUMNS: Column<
    GetAllShoppingCartsByUserIdQuery["getAllShoppingCartsByUserId"][0]
  >[] = [
    {
      Header: "Id#",
      accessor: (d) => {
        return (
          <a
            className="underline cursor-pointer text-info-50"
            href={`/players/shopping-cart/${d.id}`}
            target="_blank"
            rel="noreferrer"
          >
            {d.id}
          </a>
        );
      },
    },
    {
      Header: "Created At",
      id: "createdAt",
      accessor: (d) => {
        return <div>{dayjs(d.createdAt).format("YYYY-MM-DD hh:ss")}</div>;
      },
    },
    {
      Header: "Last Active",
      id: "lastActive",
      accessor: (d) => {
        return <div>{dayjs(d.lastActive).format("YYYY-MM-DD hh:ss")}</div>;
      },
    },
    {
      Header: "Status",
      accessor: (d) => {
        return <div>{d.shoppingCartStatus?.name}</div>;
      },
    },
    {
      Header: "Transaction Amount",
      accessor: (d) => {
        // sum of all transaction amounts
        let shoppingCartTransactionTotal = 0;
        d.shoppingCartTransactions.forEach((cartTransaction) => {
          const isPurchase =
            (cartTransaction.transactionType.name.includes("Purchase") ||
              cartTransaction.transactionType.name === "League Transfer In") &&
            cartTransaction.success;
          const isCreditPurchase =
            cartTransaction.transactionType.name === "Credit Purchase";

          if (cartTransaction.success) {
            shoppingCartTransactionTotal += isPurchase
              ? cartTransaction.transactionAmount
              : -cartTransaction.transactionAmount;
          }
        });
        return (
          <div>
            {numberToMoney(shoppingCartTransactionTotal)}
            {/* {d.shoppingCartTransactions.reduce((acc, item) => {
              return acc + item.transactionAmount;
            }, 0)} */}
          </div>
        );
      },
    },
  ];
  const REGISTRATION_COLUMNS: Column<any>[] = [
    {
      Header: "Id#",
      accessor: "id",
      // accessor: (d: any) => {
      //   return <div>{d.id}</div>;
      // },
    },
    {
      Header: "Created At",
      accessor: (d: any) => {
        return dayjs(d.createdAt).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      Header: "Region",
      accessor: "session.league.sportFormat.region.name",
    },
    {
      Header: "Reg Type",
      accessor: "registrationType.type",
    },
    {
      Header: "Team Name",
      accessor: "team.name",
    },
    {
      Header: "Day",
      accessor: (d: any) => {
        return DayOfWeek[d?.session?.dayOfWeek];
      },
    },
    {
      Header: "League",
      accessor: "session.league.name",
    },
    {
      Header: "Season",
      accessor: "session.registrationBatch.name",
    },
    {
      Header: "Shopping Cart Id",
      accessor: (d: any) => {
        return (
          <a
            className="underline cursor-pointer text-info-50"
            href={`/players/shopping-cart/${d?.shoppingCart?.id}`}
            target="_blank"
            rel="noreferrer"
          >
            {d?.shoppingCart?.id}
          </a>
        );
      },
    },
    {
      Header: "Amount",
      accessor: (d: any) => {
        return `$${d?.shoppingCartItem?.amountPaid}`;
      },
    },
  ];

  const TEAM_COLUMNS: Column<
    TeamPlayerByUserIdQuery["teamPlayerByUserId"][0]
  >[] = [
    {
      Header: "Id#",
      accessor: (d) => {
        return <Body2>{d.id}</Body2>;
      },
    },
    {
      Header: "Created At",
      accessor: (d) => {
        return <Body2>{dayjs(d.createdAt).format("MMM D, YYYY h:mm A")}</Body2>;
      },
    },
    {
      Header: "Team",
      accessor: (d) => {
        return (
          <Body2>
            <a
              className="underline cursor-pointer text-info-50"
              href={
                process.env.NODE_ENV === "production"
                  ? `http://jamsports.com/teams/team-info/${d.team.id}?userId=${params.id}`
                  : `http://jamitall.com/teams/team-info/${d.team.id}?userId=${params.id}`
              }
              target="_blank"
              rel="noreferrer"
            >
              {d?.team?.name}
            </a>
          </Body2>
        );
      },
    },
    {
      Header: "Region",
      accessor: (d) => {
        return <Body2>{d.team.session.region.name}</Body2>;
      },
    },
    {
      Header: "Reg Batch",
      accessor: (d) => {
        return <Body2>{d.team.session.registrationBatch.name}</Body2>;
      },
    },
    {
      Header: "League",
      accessor: (d) => {
        return <Body2>{d.team.session.league.name}</Body2>;
      },
    },
    {
      Header: "Day",
      accessor: (d) => {
        return <Body2>{DayOfWeek[d.team.session.dayOfWeek]}</Body2>;
      },
    },
    {
      Header: "Team Type",
      accessor: (d) => {
        return <Body2>{d.team.isIndyTeam ? "Free Agent" : "Team"}</Body2>;
      },
    },
    {
      Header: "Player Role",
      accessor: (d) => {
        return <Body2>{d.teamPlayerRole.teamPlayerRoleName}</Body2>;
      },
    },
    {
      Header: "Team Invites",
      accessor: (d) => {
        return (
          <Button
            variant="secondary"
            onClick={async () => {
              setOpenTeamInvitationDialog(true);
              setTeamInvitationDialogDetails({
                teamName: d.team.name,
                captainName:
                  d.team.teamCaptain?.playerUserProfile?.firstName ??
                  "No captain (should not happen)",
                teamId: d.team.id,
              });
              await getTeamInvitationsByTeamId({
                variables: { teamId: d.team.id },
              });
            }}
          >
            {teamInvitationsLoading ? (
              <CircularProgress size="small" />
            ) : (
              <GroupOutlinedIcon />
            )}
          </Button>
        );
      },
    },
  ];

  const TEAM_INVITATION_COLUMNS: ColumnDef<
    TeamInvitationsByTeamIdQuery["teamInvitationsByTeamId"][0]
  >[] = [
    {
      header: "Sent Date",
      cell: ({ row }) => {
        return dayjs(row.original.createdAt).format("MMM D, YYYY h:mm A");
      },
    },
    {
      header: "Email",
      cell: ({ row }) => {
        return row.original.email;
      },
    },
    {
      header: "User Id",
      cell: ({ row }) => {
        return row.original.userId ?? "N/A";
      },
    },
    {
      header: "Player Name",
      cell: ({ row }) => {
        if (row.original.user) {
          return `${row.original.user.firstName} ${row.original.user.lastName}`;
        }
        return "N/A";
      },
    },
    {
      header: "Status",
      cell: ({ row }) => {
        return row.original.teamInvitationStatus.name;
      },
    },
  ];
  const EMAIL_COLUMNS_MAILGUN: Column<any>[] = [
    {
      Header: "Date",
      accessor: (d: any) => {
        return dayjs(d.createdAt).format("MMM D, YYYY h:mm A");
      },
    },
    {
      Header: "From",
      accessor: "from",
    },
    {
      Header: "Subject",
      accessor: "subject",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Clicked",
      accessor: "clicked",
    },
    {
      Header: "Opened",
      accessor: "opened",
    },
  ];

  const CREDIT_COLUMNS: Column<CreditsByUserIdQuery["creditsByUserId"][0]>[] = [
    {
      Header: "Id",
      accessor: (d) => {
        return <Body2>{d.id}</Body2>;
      },
      Footer: "Totals",
    },
    {
      Header: "Created At",
      accessor: (d) => {
        return <Body2>{dayjs(d.createdAt).format("YYYY-MM-DD")}</Body2>;
      },
    },
    {
      Header: "Created By",
      accessor: (d) => {
        return (
          <Body2>
            {d.createdByUser.firstName} {d.createdByUser.lastName}
          </Body2>
        );
      },
    },
    {
      Header: "Credit Type",
      accessor: (d) => {
        return <Body2>{d.creditType.name}</Body2>;
      },
    },
    {
      Header: "Amount",
      accessor: (d) => {
        return <Body2>{numberToMoney(d.amount)}</Body2>;
      },
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () => rows.reduce((sum, row) => sum + row.original.amount, 0),
          [rows]
        );

        const formattedTotal = numberToMoney(total);

        return <div>{formattedTotal}</div>;
      },
    },
    {
      Header: "Current Balance",
      accessor: (d) => {
        return <Body2>{numberToMoney(d.currentBalance)}</Body2>;
      },
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () => rows.reduce((sum, row) => sum + row.original.currentBalance, 0),
          [rows]
        );

        const formattedTotal = numberToMoney(total);

        return <div>{formattedTotal}</div>;
      },
    },
    {
      Header: "Expiry Date",
      accessor: (d) => {
        return <Body2>{dayjs(d.expiryDate).format("YYYY-MM-DD")}</Body2>;
      },
    },
    {
      Header: "Shopping Cart Item Id",
      accessor: (d) => {
        if (!d.shoppingCart) {
          return <Body2>N/A</Body2>;
        }
        return (
          <a
            className="underline cursor-pointer text-info-50"
            href={`/players/shopping-cart/${d.shoppingCart.id}`}
            target="_blank"
            rel="noreferrer"
          >
            {d.shoppingCartItemId}
          </a>
        );
      },
    },
  ];

  const EMAIL_COLUMNS: Column<any>[] = [
    {
      Header: "Date",
      accessor: (d: any) => {
        return dayjs(d.createdAt).format("MMM D, YYYY h:mm A");
      },
    },
    {
      Header: "From",
      accessor: "from",
    },
    {
      Header: "Subject",
      accessor: "subject",
    },
    {
      Header: "Options",
      accessor: (d: any) => {
        return (
          <Button
            variant="secondary"
            onClick={() => handlePreviewEmail(d.body)}
          >
            Preview
          </Button>
        );
      },
    },
  ];

  const start = (paginationMailgun.page || 0) * pageSize;
  const end = start + pageSize;
  const cartData = useMemo(
    () => (shoppingCarts ? shoppingCarts : []),
    [shoppingCarts]
  );
  const registrationColumns = useMemo(() => REGISTRATION_COLUMNS, []);
  const registrationData = useMemo(() => registrations, [registrations]);
  const teamInvitationData = useMemo(() => teamInvitations, [teamInvitations]);
  const teamData = useMemo(() => teams, [teams]);
  const creditData = useMemo(() => credits, [credits]);
  const emailColumnsMailgun = useMemo(() => EMAIL_COLUMNS_MAILGUN, []);
  const emailColumns = useMemo(() => EMAIL_COLUMNS, []);
  const emailsMailgunData = useMemo(
    () =>
      emailLogsMailgunByUser
        ? emailLogsMailgunByUser.getEmailLogsMailgunByUser.mailgun.slice(
            start,
            end
          )
        : [],
    [emailLogsMailgunByUser, paginationMailgun]
  );
  const emailsData = useMemo(
    () => (emailLogsByUser ? emailLogsByUser.getEmailLogsByUser.email : []),
    [emailLogsByUser, paginationEmail]
  );

  const cartTable = (
    <BaseTable
      columns={CART_COLUMNS}
      data={cartData}
    />
  );

  const registrationTable = (
    <div className="flex flex-col gap-3">
      <Button
        variant="primary"
        onClick={() => {
          setOpenWaitlistDialog(true);
        }}
        width="1/4"
      >
        Add To Waitlist
      </Button>
      <BaseTable
        data={registrationData}
        columns={registrationColumns}
      />
    </div>
  );

  const emailsMailgunTable = (
    <BaseTable
      data={emailsMailgunData}
      columns={emailColumnsMailgun}
      pagination={paginationMailgun}
      handleChangePagination={handleChangePaginationMailgun}
    />
  );

  const emailsTable = (
    <BaseTable
      data={emailsData}
      columns={emailColumns}
      pagination={paginationEmail}
      handleChangePagination={handleChangePaginationEmail}
      showPagination={false}
    />
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUpdateUser = () => {
    if (!user.firstName || !user.lastName || !isEditPlayer) {
      dispatch(displayAlertError("Error: Please fill out all required fields"));
      return;
    }

    const permissions =
      user.permission &&
      user.permission.map((perm) => {
        return {
          id: perm.id ? perm.id : null,
          permissionId: perm.permission.id,
          regionId: perm.region ? perm.region.id : 0,
        };
      });

    UpdateUser({
      variables: {
        updateUserId: isEditPlayer,
        user: {
          firstName: user.firstName,
          lastName: user.lastName,
          genderIdentityId: user.genderIdentityId,
          birthDate: dayjs(user.birthDate).format("YYYY-MM-DD") ?? undefined,
          phoneNumber: user.phoneNumber,
          isStaff: user.isStaff ?? false,
          isAdmin: user.isAdmin ?? false,
          marketingEmail: user.marketingEmail,
          permission: permissions,
          privacyGender: user.privacyGender,
          privacyPhoneNumber: user.privacyPhoneNumber,
          regionId: user.regionId,
          shirtSizeId: user.shirtSizeId,
        },
      },
      refetchQueries: [ListAllOperations.Query.UserByIdGet],
      onCompleted: (data) => {
        if (data.updateUser) {
          dispatch(displayAlertSuccess("User updated successfully"));
        } else {
          dispatch(displayAlertError("Error: User not updated"));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });
    return;
  };

  const { loading: loadingUserInfo } = useUserByIdGetQuery({
    variables: {
      userByIdGetId: isEditPlayer!,
    },
    skip: isEditPlayer === undefined,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setUser({
        ...data.userByIdGet,
      });
    },
  });

  useEffect(() => {
    if (tabGroupValue === "Cart" && isEditPlayer) {
      retrieveExistingCart(isEditPlayer);
    } else if (tabGroupValue === "Registration" && isEditPlayer) {
      retrieveRegistrations(isEditPlayer);
    } else if (tabGroupValue === "Team" && isEditPlayer) {
      retrieveTeams(isEditPlayer);
    } else if (tabGroupValue === "Email Logs" && isEditPlayer && user.email) {
      retrieveEmailLogs(user.email);
    } else if (tabGroupValue === "Credit" && isEditPlayer) {
      getCreditsByUserId({ variables: { userId: isEditPlayer } });
    }
  }, [dispatch, tabGroupValue]);

  useEffect(() => {
    dispatch(getAllPermissions());
    dispatch(getPrivacySettings({}));
  }, []);

  // useEffect(() => {
  //   if (emailLogsByUser && emailLogsByUser.getEmailLogsByUser) {
  //     setPaginationEmail({
  //       pageCount: Math.ceil(
  //         emailLogsByUser.getEmailLogsByUser.count / pageSize
  //       ),
  //       pageSize: pageSizeDropdown,
  //       page: 0,
  //     });
  //   }
  // }, [emailLogsByUser]);

  useEffect(() => {
    if (
      emailLogsMailgunByUser &&
      emailLogsMailgunByUser.getEmailLogsMailgunByUser
    ) {
      setPaginationMailgun({
        pageCount: Math.ceil(
          emailLogsMailgunByUser.getEmailLogsMailgunByUser.mailgun.length /
            pageSize
        ),
        pageSize: pageSize,
        page: 0,
      });
    }
  }, [emailLogsMailgunByUser]);

  useEffect(() => {
    if (
      teamInvitationEmail.endsWith(" ") ||
      teamInvitationEmail.endsWith(",")
    ) {
      let result = TeamInvitationEmailSchema.safeParse(
        teamInvitationEmail.slice(0, -1)
      );
      if (!result.success) {
        dispatch(displayAlertError(result.error.errors[0].message));
      } else {
        setTeamInvitationEmails((prevState) => [
          ...prevState,
          teamInvitationEmail.slice(0, -1),
        ]);
        setTeamInvitationEmail("");
      }
    }
  }, [teamInvitationEmail]);

  useEffect(() => {
    if (user.email) {
      getEmailLogsByUser({
        variables: {
          email: user.email,
          pageSize: paginationEmail.pageSize,
          page: paginationEmail.page,
        },
      });
    }
  }, [paginationEmail.pageSize]);

  if (iframeRef.current && iframeRef) {
    const formattedBody = `${selectedEmail.body.replace(
      /%%button_(https:\/\/[a-zA-Z0-9._%+-\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
      (_: any, link: string, text: string) => {
        return emailButton(link, text);
      }
    )}`;
    const blob = new Blob([formattedBody], { type: "text/html" });
    iframeRef.current.src = URL.createObjectURL(blob);
  }

  useEffect(() => {
    if (selectedEmail.body && openModal) {
      iframeRef.current?.focus();
      setTimeout(() => {
        if (iframeRef.current) {
          const formattedBody = `${selectedEmail.body.replace(
            /%%button_(https:\/\/[a-zA-Z0-9._%+-\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
            (_: any, link: string, text: string) => {
              return emailButton(link, text);
            }
          )}`;
          const blob = new Blob([formattedBody], { type: "text/html" });
          iframeRef.current.src = URL.createObjectURL(blob);
        }
      }, 1000);
    }
  }, [selectedEmail, openModal]);

  if (loading || isLoading) {
    return (
      <div>
        <LoadingMaterialUI />
      </div>
    );
  }
  return (
    <>
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/venues"
      ></BreadCrumbs>
      <div className="flex items-center justify-between h-16 mt-5">
        <div>
          <Headline1Variable>{`${
            user.firstName + " " + user.lastName + " (" + user.id + ")"
          } `}</Headline1Variable>
        </div>
        <div className="flex flex-row items-center gap-4 md:max-2xl:justify-end:items-end:self-end:content-end:justify-self-end">
          {/* <Button variant="primary" onClick={handleUpdateUser} type="submit">
            Save
          </Button> */}
        </div>
      </div>
      <div className="flex w-[1000px] pb-6">
        <TabGroup
          content={[
            "Info",
            "Cart",
            "Registration",
            "Team",
            "Email Logs",
            admin?.isSuperAdmin ? "Permission" : "",
            "Credit",
            "Notification",
          ]}
          inputChange={(value) => {
            setTabGroupValue(value);
          }}
          value={tabGroupValue}
          className="max-w-full w-fit"
        />
      </div>
      {tabGroupValue === "Info" && (
        <div className="flex flex-col gap-5">
          {(!user.phoneNumber || !user.birthDate) && (
            <Disclaimer
              variant="warning"
              content="Some fields are missing."
              size="large"
              persist={true}
              mt={"mt-1"}
            />
          )}
          <Card>
            {/* Row 1: FirstName, LastName and Gender */}
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormField
                className="border-neutral-20 bg-neutral-60"
                initialValue={user.email ? user.email : ""}
                inputChange={() => {}}
                assistiveText={
                  user.emailChange
                    ? `Pending change to ${user.emailChange}`
                    : ""
                }
                name="email"
                label="Email"
                disabled
              />
              <FormField
                initialValue={user.firstName ? user.firstName : ""}
                inputChange={handleChange}
                name="firstName"
                label="First Name"
              />
              <FormField
                initialValue={user.lastName ? user.lastName : ""}
                inputChange={handleChange}
                name="lastName"
                label="Last Name"
              />
            </div>
          </Card>
          <Card>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormFieldSelect
                value={
                  user.genderIdentityId ? user.genderIdentityId.toString() : "0"
                }
                inputChange={(value) => {
                  setUser((prevState) => ({
                    ...prevState,
                    genderIdentityId: +value,
                  }));
                }}
                name="genderIdentity"
                label="Gender"
                placeholder="Gender"
              >
                {[
                  { id: 0, name: "Select a gender" },
                  ...[
                    { id: 1, name: "Man" },
                    { id: 2, name: "Woman" },
                    { id: 3, name: "Nonbinary" },
                    { id: 4, name: "Trans Man" },
                    { id: 5, name: "Trans Woman" },
                    { id: 6, name: "Genderqueer" },
                    { id: 7, name: "Other" },
                    { id: 8, name: "Prefer not to say" },
                  ],
                ]}
              </FormFieldSelect>
              <FormFieldDateSelect
                initialValue={
                  user.birthDate
                    ? moment(user.birthDate).format("YYYY-MM-DD")
                    : null
                }
                inputChange={(val) => {
                  handleChange(val);
                }}
                name="birthDate"
                label="Birth Date"
              />
              <FormField
                onWheel={(e) => e.preventDefault()}
                initialValue={user.phoneNumber ? user.phoneNumber : ""}
                inputChange={handleChange}
                name="phoneNumber"
                label="Phone Number"
              />
            </div>
            <div className="grid grid-cols-1 gap-6 mt-5 md:grid-cols-3 md:max-xl">
              <FormFieldSelect
                value={user.regionId ? user.regionId.toString() : "0"}
                inputChange={(value) => {
                  setUser((prevState) => ({
                    ...prevState,
                    regionId: +value,
                  }));
                }}
                name="regionId"
                label="Region"
                placeholder="Region"
              >
                {[{ id: 0, name: "Select a region" }, ...selectedRegions]}
              </FormFieldSelect>
              <FormFieldSelect
                value={user.shirtSizeId ? user.shirtSizeId.toString() : "0"}
                inputChange={(value) => {
                  setUser((prevState) => ({
                    ...prevState,
                    shirtSizeId: +value,
                  }));
                }}
                name="shirtSizeId"
                label="Shirt Size"
                placeholder="Shirt Size"
              >
                {[{ id: 0, name: "Select a Shirt Size" }, ...shirtSizes]}
              </FormFieldSelect>
            </div>
          </Card>
          <Card>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormFieldSelect
                value={user.privacyGender ? user.privacyGender.toString() : "0"}
                inputChange={(value) => {
                  setUser((prevState) => ({
                    ...prevState,
                    privacyGender: +value,
                  }));
                }}
                name="privacyGender"
                label="Privacy Gender"
                placeholder="Privacy Gender"
              >
                {privacySettings}
              </FormFieldSelect>
              <FormFieldSelect
                value={
                  user.privacyPhoneNumber
                    ? user.privacyPhoneNumber.toString()
                    : "0"
                }
                inputChange={(value) => {
                  setUser((prevState) => ({
                    ...prevState,
                    privacyPhoneNumber: +value,
                  }));
                }}
                name="privacyPhoneNumber"
                label="Privacy Phone Number"
                placeholder="Privacy Phone Number"
              >
                {privacySettings}
              </FormFieldSelect>
            </div>
          </Card>
          <Card>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormFieldDateSelect
                className="border-neutral-20 bg-neutral-60"
                initialValue={
                  user.createdAt
                    ? moment(user.createdAt).format("YYYY-MM-DD")
                    : null
                }
                inputChange={() => {}}
                name="createdAt"
                label="Created At"
                disabled
              />
              {user.lastLogin ? (
                <FormFieldDateSelect
                  className="border-neutral-20 bg-neutral-60"
                  initialValue={
                    user.lastLogin
                      ? moment(user.lastLogin).format("YYYY-MM-DD")
                      : null
                  }
                  inputChange={() => {}}
                  name="lastLogin"
                  label="Last Login"
                  disabled
                />
              ) : (
                <FormField
                  className="border-neutral-20 bg-neutral-60"
                  initialValue="User never logged in"
                  inputChange={() => {}}
                  name=""
                  label="Last Login"
                  disabled
                />
              )}
              <FormFieldDateSelect
                className="border-neutral-20 bg-neutral-60"
                initialValue={
                  user.updatedAt
                    ? moment(user.updatedAt).format("YYYY-MM-DD")
                    : null
                }
                inputChange={() => {}}
                name="updatedAt"
                label="Last Updated"
                disabled
              />
            </div>
          </Card>
          <Card>
            <CheckBox
              label="Staff Permission"
              defaultChecked={false}
              checkedState={user.isStaff}
              inputChange={(val) => {
                setUser((prevState) => ({
                  ...prevState,
                  isStaff: val,
                }));
              }}
              id="isStaff"
            />
          </Card>
          <div className="flex flex-row-reverse mt-6">
            <Button
              variant="primary"
              onClick={handleUpdateUser}
              type="submit"
            >
              Save
            </Button>
          </div>
        </div>
      )}
      {tabGroupValue === "Cart" && (
        <div className="mt-3">
          {cartLoading ? <LoadingMaterialUI /> : cartTable}
        </div>
      )}
      {tabGroupValue === "Registration" && (
        <div className="mt-3">
          {registrationLoading ? <LoadingMaterialUI /> : registrationTable}
        </div>
      )}
      {tabGroupValue === "Team" && (
        <div className="mt-3">
          {teamPlayerLoading ? (
            <LoadingMaterialUI />
          ) : (
            <BaseTable
              data={teamData}
              columns={TEAM_COLUMNS}
            />
          )}
        </div>
      )}
      {tabGroupValue === "Permission" && admin.isSuperAdmin && (
        <div>
          <Card className="mt-3">
            <div className="flex flex-col gap-4 md:items-start">
              <CheckBox
                label="Admin Permission"
                defaultChecked={false}
                checkedState={user.isAdmin}
                inputChange={(val) => {
                  setUser((prevState) => ({
                    ...prevState,
                    isAdmin: val,
                  }));
                }}
                id="isAdmin"
              />
            </div>
          </Card>
          <Card className="mt-5">
            <Subtitle1>Permission - Region</Subtitle1>
            {user.permission.length === 0 && (
              <Subtitle1>No existing Permission</Subtitle1>
            )}
            <div className="flex flex-col">
              {user.permission.map((permission, index: number) => {
                return (
                  <div
                    className="flex flex-row mt-3"
                    key={index}
                  >
                    {permission.id !== 0 ? (
                      <div className="flex flex-row items-center justify-center gap-4">
                        <Subtitle1>{`${permission.permission.name} - ${
                          permission.region ? permission.region.name : "All"
                        }`}</Subtitle1>
                        <div className="max-w-1/2">
                          <MuiButton
                            sx={{
                              padding: "0px",
                              minWidth: "0px",
                              minHeight: "0px",
                              width: "24px",
                              height: "24px",
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                          >
                            <CloseIcon
                              className="text-error-30 hover:text-error-10"
                              onClick={(e) => {
                                setUser((prevState) => ({
                                  ...prevState,
                                  permission: prevState.permission.filter(
                                    (_, i) => i !== index
                                  ),
                                }));
                              }}
                            />
                          </MuiButton>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-row items-center justify-center w-1/2 gap-4">
                        <FormFieldSelect
                          value={
                            user.permission.length - 1 >= index
                              ? user.permission[index].permission.id.toString()
                              : "0"
                          }
                          name="permission"
                          placeholder="All"
                          inputChange={(value: string) => {
                            setUser((prevState) => {
                              const permission = permissions.find(
                                (permission: any) => permission.id === +value
                              );
                              if (permission) {
                                const curPermissions = prevState.permission;
                                curPermissions[index].permission = permission;
                                return {
                                  ...prevState,
                                  permission: curPermissions,
                                };
                              }
                              return prevState;
                            });
                          }}
                          label="Permission"
                        >
                          {[
                            { id: "0", name: "Select Permission" },
                            ...permissions,
                          ]}
                        </FormFieldSelect>
                        <FormFieldSelect
                          value={
                            user.permission.length - 1 >= index
                              ? user.permission[index].region?.id.toString() ??
                                "0"
                              : "0"
                          }
                          name="region"
                          placeholder="All"
                          inputChange={(value: string) => {
                            setUser((prevState) => {
                              const region = regions.find(
                                (region: any) => region.id === +value
                              );
                              if (region) {
                                const curPermissions = prevState.permission;
                                curPermissions[index].region = region;
                                return {
                                  ...prevState,
                                  permission: curPermissions,
                                };
                              }
                              return prevState;
                            });
                          }}
                          label="Region"
                        >
                          {[{ id: "0", name: "All" }, ...regions]}
                        </FormFieldSelect>
                        <MuiButton
                          sx={{
                            padding: "0px",
                            minWidth: "0px",
                            marginTop: "16px",
                            minHeight: "0px",
                            width: "24px",
                            height: "24px",
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          <CloseIcon
                            className="text-error-30 hover:text-error-10"
                            onClick={() => {
                              setUser((prevState) => ({
                                ...prevState,
                                permission: prevState.permission.filter(
                                  (_, i) => i !== index
                                ),
                              }));
                            }}
                          />
                        </MuiButton>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <Button
              className="mt-5"
              variant="primary"
              onClick={() => {
                setUser((prevState) => {
                  let newPerm: UserByIdGetQuery["userByIdGet"]["permission"][0] =
                    structuredClone(
                      prevState.permission[prevState.permission.length - 1]
                    );
                  newPerm.id = 0;
                  newPerm.permission.id = 0;
                  newPerm.permission.name = "Select Permission";
                  if (newPerm.region) {
                    newPerm.region.id = 0;
                    newPerm.region.name = "All";
                  } else {
                    newPerm.region = {
                      id: 0,
                      name: "All",
                    };
                  }

                  return {
                    ...prevState,
                    permission: [...prevState.permission, newPerm],
                  };
                });
              }}
            >
              Add Permissions
            </Button>
          </Card>
          <div className="flex flex-row-reverse mt-6">
            <Button
              variant="primary"
              onClick={handleUpdateUser}
              type="submit"
            >
              Save
            </Button>
          </div>
        </div>
      )}
      {tabGroupValue === "Email Logs" && (
        <div>
          <div className="flex flex-col gap-3 mt-3">
            <Headline2Variable>
              Email Provider Logs (7 days history)
            </Headline2Variable>
            <Card>
              {emailLogsMailgunLoading ? (
                <LoadingMaterialUI />
              ) : (
                emailsMailgunTable
              )}
            </Card>
            <Headline2Variable>{`J.U.D.I. Logs`}</Headline2Variable>
            {/* dropdown for pagination 20, 100, All */}
            <div className="w-40">
              <FormFieldSelect
                value={
                  paginationEmail.pageSize
                    ? paginationEmail.pageSize.toString()
                    : "0"
                }
                name="rows"
                placeholder="All"
                inputChange={(value: string) => {
                  if (value === "0") {
                    setPaginationEmail((prev) => ({
                      ...prev,
                      pageSize: undefined,
                      page: 0,
                    }));
                  } else {
                    setPaginationEmail({
                      pageSize: +value,
                      page: 0,
                    });
                  }
                }}
                label="No. of Emails"
              >
                {[
                  {
                    id: pageSizeDropdown.toString(),
                    name: pageSizeDropdown.toString(),
                  },
                  { id: "100", name: "100" },
                  { id: "0", name: "All" },
                ]}
              </FormFieldSelect>
            </div>
            <Card>
              {emailLogsLoading ? <LoadingMaterialUI /> : emailsTable}
            </Card>
          </div>
        </div>
      )}
      {tabGroupValue === "Notification" && <Notification />}

      {tabGroupValue === "Credit" && (
        <div className="mt-3">
          {creditsLoading ? (
            <LoadingMaterialUI />
          ) : (
            <BaseTable
              data={creditData}
              columns={CREDIT_COLUMNS}
            />
          )}
        </div>
      )}
      <Dialog
        open={openModal}
        onOpenChange={(openState) => {
          if (!openState) handleCloseDialog();
        }}
      >
        <DialogContent className="max-w-[800px] max-h-[90%] h-[80%] overflow-scroll">
          <div className="flex flex-col justify-between w-full h-full gap-4">
            <iframe
              ref={iframeRef}
              title="Email Preview"
              style={{ width: "700px", height: "100%", border: "none" }}
            />
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openTeamInvitationDialog}
        onOpenChange={(value) => setOpenTeamInvitationDialog(value)}
      >
        <DialogContent className="flex flex-col flex-grow w-fit max-w-[80%] max-h-[80%] overflow-scroll">
          <DialogHeader>
            <Headline2Variable>Team Invites</Headline2Variable>
          </DialogHeader>
          <div className="flex flex-col flex-grow max-w-full gap-4">
            <div className="flex flex-col gap-2">
              <Subtitle2>
                Team Name: {teamInvitationDialogDetails.teamName}
              </Subtitle2>
              <Subtitle2>
                Team Captain: {teamInvitationDialogDetails.captainName}
              </Subtitle2>
              <div className="flex flex-row items-center justify-center w-full">
                {teamInvitationsLoading ? (
                  <CircularProgress />
                ) : (
                  <div className="max-h-[450px] overflow-scroll border rounded-md">
                    {teamInvitationData.length > 0 && (
                      <DataTable
                        data={teamInvitationData}
                        columns={TEAM_INVITATION_COLUMNS}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div>
                <Headline2Variable>Send Team Invites</Headline2Variable>
                <Button
                  variant="primary"
                  onClick={handleSendTeamInvitations}
                >
                  <div className="flex flex-row gap-2">
                    <Body1>Send Team Invites</Body1>
                    {loadingTeamInvitationCreate && (
                      <LoaderCircle className="w-fit h-fit animate-spin" />
                    )}
                  </div>
                </Button>
              </div>
              <div className="flex flex-row items-end mt-3 gap-x-2">
                <FormField
                  inputChange={(e) => {
                    setTeamInvitationEmail(e.target.value.toLowerCase());
                  }}
                  name="email"
                  label="Invite with Email"
                  initialValue={teamInvitationEmail}
                  className="h-fit"
                />
                <Button
                  onClick={() => {
                    setTeamInvitationEmails((prevState) => [
                      ...prevState,
                      teamInvitationEmail,
                    ]);
                    setTeamInvitationEmail("");
                  }}
                  variant="secondary"
                  className="rounded-full h-fit"
                >
                  <AddIcon />
                </Button>
              </div>
              <div className="flex flex-wrap">
                {teamInvitationEmails.length > 0 &&
                  teamInvitationEmails.map((email: String, i) => (
                    <span
                      key={i}
                      className="flex flex-row items-center justify-center gap-2 px-4 py-2 mt-3 mr-2 text-sm text-gray-800 break-words bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300"
                    >
                      {email}
                      <CloseIcon
                        className="transition-all rounded-full cursor-pointer hover:text-error-30 hover:bg-error-90"
                        onClick={() => {
                          setTeamInvitationEmails((prevState) => {
                            return prevState.filter((item) => item !== email);
                          });
                        }}
                      />
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <WaitlistSessionsDialog
        open={openWaitlistDialog}
        onOpenChange={(value) => setOpenWaitlistDialog(value)}
        userId={isEditPlayer || ""}
      />
    </>
  );
};

export default Player;
