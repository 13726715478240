import { Button as MuiButton, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormRow, FormRowSelect, LoadingMaterialUI } from "../UI";
import classes from "./CreateContract.module.css";
import ReactDatePicker from "react-datepicker";
import MultipleDatePicker from "react-multi-date-picker";
import DateObject from "react-date-object";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-datepicker/dist/react-datepicker.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import Attachment from "../UI/Attachment";
import {
  getVendors,
  getAllVenuesByVendorId,
  createContract,
  editContract,
  handleContractValues,
  uploadAttachments,
  getContractById,
  toggleView,
} from "../../app/venueMasterSlice";
import { Contract, ContractDetails, ContractItem } from "../../types/types";
import moment from "moment";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Card from "../UI/Card/Card";
import FormField from "../UI/FormField/FormField";
import FormFieldDateSelect from "../UI/FormField/FormFieldDate/FormFieldDateSelect";
import FormFieldDropdown from "../UI/FormField/FormFieldDropdown/FormFieldDropdown";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import Button from "../UI/Button/Button";
import Subtitle2 from "../UI/Text/Subtitle/Subtitle2";

dayjs.extend(utc);

const OldCreateContract: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    isLoading,
    contract,
    vendorList,
    venueList,
    isEditContract,
    selectedRegions,
  }: any = useSelector((state: RootState) => state.venueMaster);

  const params = useParams();
  const { id } = params;

  const [values, setValues] = useState<Contract>(contract);
  // const [values, setValues] = useState<ContractDetails>(contract);
  const [attachments, setAttachments] = useState<String>(contract.attachments);

  useEffect(() => {
    dispatch(getVendors({}));
    if (contract.vendorId) dispatch(getAllVenuesByVendorId(contract.vendorId));
    if (id) {
      dispatch(toggleView(true));
      dispatch(getContractById(+id));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllVenuesByVendorId(contract.vendorId));
  }, [dispatch, contract.vendorId]);

  // useEffect(() => {
  //   if (values.attachments !== contract.attachments) {
  //     setValues({ ...values, attachments: contract.attachments });
  //   }
  //   setAttachments(contract.attachments);
  // }, [contract]);

  // handleChange for Contract Details
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      handleContractValues({
        ...contract,
        [event.target.name]: +event.target.value || event.target.value,
      })
    );
  };

  // handleChange for Vendor in Contract Details to populate Venue List in Contract Item
  const handleChangeVendor = (event: React.ChangeEvent<HTMLInputElement>) => {
    const vendorId = Number(event.target.value);

    dispatch(getAllVenuesByVendorId(vendorId));

    dispatch(
      handleContractValues({
        ...contract,
        [event.target.name]: event.target.value,
      })
    );
  };

  // handleChange for Contract Items
  const handleChangeContractItem = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
    elementName: keyof ContractItem
  ): void => {
    let temp: ContractItem[] = contract.contractItems.map(
      (contractItem: ContractItem, i: number) => {
        if (i === index) {
          return {
            ...contractItem,
            [elementName]: event.target.value,
          };
        }
        return { ...contractItem };
      }
    );

    dispatch(
      handleContractValues({
        regionId: contract.regionId,
        vendorId: contract.vendorId,
        type: contract.type,
        name: contract.name,
        status: contract.status,
        approver: contract.approver,
        grossCost: contract.grossCost,
        taxes: contract.taxes,
        notes: contract.notes,
        contractItems: [...temp],
        attachments: contract.attachments,
      })
    );
  };

  // handleChange for time in Contract Item (as DatePicker doesnt return event but date only)
  const handleChangeTime = (
    index: number,
    date: Date | null,
    elementName: keyof ContractItem
  ): void => {
    if (!date) return;

    let temp: ContractItem[] = contract.contractItems.map(
      (contractItem: ContractItem, i: number) => {
        if (i === index) {
          return {
            ...contractItem,
            [elementName]: moment(date).format("YYYY-MM-DD HH:mm"),
          };
        }
        return { ...contractItem };
      }
    );

    dispatch(
      handleContractValues({
        regionId: contract.regionId,
        vendorId: contract.vendorId,
        type: contract.type,
        name: contract.name,
        status: contract.status,
        approver: contract.approver,
        grossCost: contract.grossCost,
        taxes: contract.taxes,
        notes: contract.notes,
        contractItems: [...temp],
        attachments: contract.attachments,
      })
    );
  };

  // handleChange for exclusion Date in Contract Item (as MultipleDatePicker doesnt return event but date only)
  // All three handleChange can be refactored using Generics
  const handleChangeExclusionDates = (
    index: number,
    selectedDates: DateObject[],
    elementName: keyof ContractItem
  ): void => {
    let temp: ContractItem[] = contract.contractItems.map(
      (contractItem: ContractItem, i: number) => {
        if (i === index) {
          let dates;
          if (Array.isArray(selectedDates)) {
            dates = selectedDates?.map((date) => {
              return date.format();
            });
          }
          return {
            ...contractItem,
            [elementName]: dates,
          };
        }
        return { ...contractItem };
      }
    );

    dispatch(
      handleContractValues({
        regionId: contract.regionId,
        vendorId: contract.vendorId,
        type: contract.type,
        name: contract.name,
        status: contract.status,
        approver: contract.approver,
        grossCost: contract.grossCost,
        taxes: contract.taxes,
        notes: contract.notes,
        contractItems: [...temp],
        attachments: contract.attachments,
      })
    );
  };

  const addContractItem = (): void => {
    dispatch(
      handleContractValues({
        regionId: contract.regionId,
        vendorId: contract.vendorId,
        type: contract.type,
        name: contract.name,
        status: contract.status,
        approver: contract.approver,
        grossCost: contract.grossCost,
        taxes: contract.taxes,
        notes: contract.notes,
        attachments: contract.attachments,
        contractItems: [
          ...contract.contractItems,
          {
            venueId: 0,
            day: 1,
            startDate: new Date(),
            endDate: new Date(),
            startTime: new Date(),
            endTime: new Date(),
            exclusionDates: [],
          },
        ],
      })
    );
  };

  const removeContractItem = (index: number): void => {
    let temp: ContractItem[] = [...contract.contractItems];
    if (temp.length === 1) {
      return;
    }

    temp.splice(index, 1);

    dispatch(
      handleContractValues({
        regionId: contract.regionId,
        vendorId: contract.vendorId,
        type: contract.type,
        name: contract.name,
        status: contract.status,
        approver: contract.approver,
        grossCost: contract.grossCost,
        taxes: contract.taxes,
        notes: contract.notes,
        attachments: contract.attachments,
        contractItems: temp,
      })
    );
  };

  const submitFiles = async (filesvalue: any) => {
    const form = new FormData();
    filesvalue.map((file: any) => {
      form.append("venueFiles", file.file);
    });
    dispatch(uploadAttachments(form));
  };

  const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Error Handling: Make sure that venues are from choosen vendor only!
    // Make sure if vendor resets/changes then venues are reset to 0.

    if (isEditContract) {
      // set initial State to something
      const contractId = params.id;
      dispatch(
        editContract({
          contractId,
          contract: {
            ...contract,
            regionId: +contract.regionId,
            vendorId: +contract.vendorId,
          },
        })
      );
      return;
    }

    dispatch(
      createContract({
        ...contract,
        regionId: +contract.regionId,
        vendorId: +contract.vendorId,
      })
    );
  };

  const renderAttachments = () => (
    <div className={classes.attachments}>
      {attachments?.split(",").map((attach, index) => (
        <a
          rel="noreferrer"
          key={index}
          href={attach}
          target="_blank"
        >
          {attach}
        </a>
      ))}
    </div>
  );

  const pathsBreadcrumbs = [{ name: "Contracts", url: "/ops/contracts" }];

  if (isLoading) return <LoadingDialog open={isLoading} />;

  return (
    <main>
      {/* {isEditContract ? <h1>Edit Contract</h1> : <h1>Create New Contract</h1>} */}
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/contracts"
      ></BreadCrumbs>
      <div className="flex flex-row justify-between w-full mt-6">
        <Headline1Variable>
          {isEditContract ? "Edit Contract" : "Create New Contract"}
        </Headline1Variable>
        <div className="h-10">
          <Button
            variant="primary"
            onClick={(e) => onSubmit(e)}
          >
            {isEditContract ? (
              <span> Edit Contract</span>
            ) : (
              <span> Create Contract</span>
            )}
          </Button>
        </div>
      </div>

      <form autoComplete="off">
        <Card className="mt-6">
          <Subtitle2>Contract Details</Subtitle2>
          <div className="flex flex-col">
            <div className="grid grid-cols-1 gap-6 mt-4 md:grid-cols-3 md:max-xl">
              <FormField
                type="name"
                name="name"
                value={contract.name}
                label="Name"
                inputChange={handleChange}
                initialValue={contract.name ? contract.name : ""}
              />
              <FormFieldDropdown
                initialValue={
                  contract.regionId ? contract.regionId.toString() : "0"
                }
                name="regionId"
                placeholder="All"
                inputChange={handleChange}
                label="Region"
              >
                {[{ id: "0", name: "Select a Region" }, ...selectedRegions]}
              </FormFieldDropdown>
              <FormFieldDropdown
                initialValue={
                  contract.vendorId ? contract.vendorId.toString() : "0"
                }
                name="vendorId"
                placeholder="All"
                inputChange={handleChange}
                label="Vendor"
              >
                {[{ id: "0", name: `Select a Vendor` }, ...vendorList]}
              </FormFieldDropdown>
            </div>
            <div className="grid grid-cols-1 gap-6 mt-4 md:grid-cols-3 md:max-xl">
              <FormFieldDropdown
                initialValue={
                  contract.typeId ? contract.typeId.toString() : "0"
                }
                name="typeId"
                placeholder="All"
                inputChange={handleChange}
                label="Type"
              >
                {[
                  { id: "0", name: "Select Contract Type" },
                  { id: "1", name: "Permit" },
                  { id: "2", name: "Revenue Share" },
                  { id: "3", name: "Estimate" },
                ]}
              </FormFieldDropdown>
              <FormFieldDropdown
                initialValue={
                  contract.permitStatusId
                    ? contract.permitStatusId.toString()
                    : "0"
                }
                name="permitStatusId"
                placeholder="All"
                inputChange={handleChange}
                label="Permit Status"
              >
                {[
                  { id: "0", name: "Select Permit Status" },
                  { id: "1", name: "Applied" },
                  { id: "2", name: "Pending" },
                  { id: "3", name: "Approved" },
                ]}
              </FormFieldDropdown>
              <FormFieldDropdown
                initialValue={
                  contract.approverId ? contract.approverId.toString() : "0"
                }
                name="approverId"
                placeholder="All"
                inputChange={handleChange}
                label="Approver"
              >
                {[
                  { id: 0, name: "Select Approver" },
                  { id: "1", name: "Jen Filippelli" },
                  { id: "2", name: "Graham Welsh" },
                  { id: "3", name: "Taylor Lewis" },
                  { id: "4", name: "Maximilian Jehle" },
                ]}
              </FormFieldDropdown>
            </div>
            <div className="grid grid-cols-1 gap-6 mt-4 md:grid-cols-3 md:max-xl">
              <FormField
                type="number"
                name="grossCost"
                label="Gross Cost"
                initialValue={contract.grossCost ? contract.grossCost : 0}
                value={contract.grossCost}
                inputChange={handleChange}
              ></FormField>
              <FormField
                type="number"
                name="taxes"
                label="Taxes"
                initialValue={contract.taxes ? contract.taxes : 0}
                value={contract.taxes}
                inputChange={handleChange}
              ></FormField>
            </div>
          </div>
        </Card>
      </form>

      <hr className={classes.line}></hr>
      <p>Contract Items</p>
      <div className={classes.container_item}>
        <div className={classes.header_item}>Venue</div>
        <div className={classes.header_item}>Day</div>
        <div className={classes.header_item}>Start Date</div>
        <div className={classes.header_item}>End Date</div>
        <div className={classes.header_item}>Start Time</div>
        <div className={classes.header_item}>End Time</div>
        <div className={classes["header_item_span2"]}>Exclusion Dates</div>
        <div className={classes["header_item_color"]}>A</div>

        {contract.contractItems.map((contractItem: any, index: number) => {
          // // iterating through multiple contract
          const startTime = contractItem.startTime;
          const endTime = contractItem.endTime;
          // let startTime = dayjs.utc(contractItem.startTime).format('YYYY-MM-DD HH:mm:ss')
          // let endTime = dayjs.utc(contractItem.endTime).format('YYYY-MM-DD HH:mm:ss');
          return (
            <React.Fragment key={index}>
              <div className={classes.form_item}>
                <FormRowSelect
                  name="venueId"
                  value={contractItem.venueId}
                  list={[
                    { id: 0, name: `<Select Vendor first>` },
                    ...(venueList || []),
                  ]}
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeContractItem(index, event, "venueId")
                  }
                  width="100%"
                />
              </div>
              <div className={classes.form_item}>
                <FormRowSelect
                  name="day"
                  value={contractItem.day}
                  list={[
                    { id: 0, name: "Sunday" },
                    { id: 1, name: "Monday" },
                    { id: 2, name: "Tuesday" },
                    { id: 3, name: "Wednesday" },
                    { id: 4, name: "Thursday" },
                    { id: 5, name: "Friday" },
                    { id: 6, name: "Saturday" },
                  ]}
                  width="100%"
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeContractItem(index, event, "day")
                  }
                />
              </div>
              <div className={classes.form_item}>
                <FormRow
                  type="date"
                  name="startDate"
                  width="100%"
                  value={moment(contractItem.startDate).format("YYYY-MM-DD")}
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeContractItem(index, event, "startDate")
                  }
                />
              </div>
              <div className={classes.form_item}>
                <FormRow
                  type="date"
                  name="endDate"
                  width="100%"
                  value={moment(contractItem.endDate).format("YYYY-MM-DD")}
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeContractItem(index, event, "endDate")
                  }
                />
              </div>
              <div className={classes.form_item}>
                <div className={classes.timepickers}>
                  <ReactDatePicker
                    wrapperClassName={classes.datepicker_date}
                    name="startTime"
                    selected={new Date(startTime)}
                    showTimeSelect
                    showTimeSelectOnly
                    popperClassName={classes["react-datepicker-popper"]}
                    timeIntervals={10}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    onChange={(date) => {
                      handleChangeTime(index, date, "startTime");
                    }}
                  />
                </div>
              </div>
              <div className={classes.form_item}>
                <div className={classes.timepickers}>
                  <ReactDatePicker
                    wrapperClassName={classes.datepicker_date}
                    name="endTime"
                    selected={new Date(endTime)}
                    showTimeSelect
                    showTimeSelectOnly
                    popperClassName={classes["react-datepicker-popper"]}
                    timeIntervals={10}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    onChange={(date) => {
                      handleChangeTime(index, date, "endTime");
                    }}
                  />
                </div>
              </div>
              <div className={classes["form_item_span2"]}>
                <div>
                  <MultipleDatePicker
                    multiple
                    plugins={[<DatePanel />]}
                    inputClass={classes["multi-DatePicker-input"]}
                    containerClassName={classes["multi-DatePicker-container"]}
                    value={contractItem.exclusionDates}
                    name="exclusionDatesField"
                    // onChange throws Date as DateObject only
                    onChange={(selectedDates: DateObject[]) =>
                      handleChangeExclusionDates(
                        index,
                        selectedDates,
                        "exclusionDates"
                      )
                    }
                  />
                </div>
              </div>
              <div className={classes["form_item_color"]}>
                <IconButton
                  sx={{ "&:hover": { color: "var(--primary-blue-500)" } }}
                  onClick={() => {
                    removeContractItem(index);
                  }}
                >
                  <HighlightOffIcon />
                </IconButton>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <hr className={classes.line}></hr>
      {/* <Button onClick={addContractItem}>Add Contract Item</Button> */}

      {/* <hr className={classes.line}></hr>
      <p>Attachments</p>
      {renderAttachments()}
      <Attachment handleFiles={submitFiles} /> */}

      {/* <hr className={classes.line}></hr>
      <p>Contract Notes</p>
      <TextField
        name='notes'
        // label="Notes"
        onChange={handleChange}
        value={contract.notes}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        multiline
        rows={8}
        sx={{
          width: '900px',
          paddingBottom: '20px',
          paddingRight: '30px',
        }}></TextField> */}

      <div className={classes.btn__container}>
        <MuiButton
          variant="contained"
          type="submit"
          className={classes.btn__submit}
          onClick={onSubmit}
          // disabled={}
          sx={{
            color: "var(--white)",
            bgcolor: "var(--primary-blue-500)",
            "&:hover": { bgcolor: "var(--primary-blue-700)" },
          }}
        >
          {isEditContract ? "Edit Contract" : "Create Contract"}
        </MuiButton>
      </div>
    </main>
  );
};

export default OldCreateContract;
