import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import { useParams } from "react-router-dom";
import { getRegistrationBatch, getSports } from "../../../app/venueMasterSlice";
import ReactQuill from "react-quill";
import CheckBox from "../../UI/Checkbox/Checkbox";
import Button from "../../UI/Button/Button";
import Body1 from "../../UI/Text/Body/Body1";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import { IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { header } from "./Headers";
import { footer } from "./Footers";
import { emailButton } from "./EmailButton";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import FormFieldControlled from "../../UI/FormField/FormFieldControlled";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import { z } from "zod";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../UI/shadcn/dialog";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import { Separator } from "../../UI/shadcn/separator";
import {
  DivisionsForEmailDivisionToolQuery,
  useDivisionByIdForDivisionEmailToolQuery,
  useDivisionsForEmailDivisionToolQuery,
  useGetEmailTemplatesQuery,
  useGetNumberOfPlayersOnTeamsLazyQuery,
  useSendDivisionEmailMutation,
} from "../../../generated/graphql";
import { emailHyperLink } from "./EmailHyperLink";
import { rootUrl } from "../../../utils/environmentDependantVars";
import { FormFieldSelectMulti } from "../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMulti";

const EmailTemplateSchema = z.object({
  id: z.number().optional(),
  subject: z.string().min(1, "Please enter a valid subject"),
  name: z.string(),
  body: z.string().refine(
    (data) => {
      return data !== "<p><br></p>";
    },
    { message: "Please enter a valid body" }
  ),
});
type EmailTemplate = z.infer<typeof EmailTemplateSchema>;

const EmailDivisionFilterSchema = z.object({
  regionId: z.number(),
  sportId: z.number(),
  registrationBatchId: z.number(),
  day: z.number(),
});
type EmailDivisionFilter = z.infer<typeof EmailDivisionFilterSchema>;

const emptyEmailTemplate = {
  id: 0,
  subject: "",
  name: "",
  body: "<p><br></p>",
};

const EmailToolDivisions = () => {
  const params = useParams();
  const divisionIdParam = params.id;

  const dispatch = useDispatch<AppDispatch>();
  const { selectedRegions, sports, registrationBatches }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  const [emailBody, setEmailBody] = useState<EmailTemplate>(emptyEmailTemplate);

  const [selectedTeams, setSelectedTeams] = useState<
    { id: number; name: string }[]
  >([]);
  const [divisionId, setDivisionId] = useState<number>(
    divisionIdParam ? +divisionIdParam : 0
  );
  const [divisions, setDivisions] = useState<
    DivisionsForEmailDivisionToolQuery["divisions"]
  >([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [numberOfPlayers, setNumberOfPlayers] = useState<number>(0);
  const [divisionFilters, setDivisionFilters] = useState<EmailDivisionFilter>({
    regionId: 0,
    day: 7,
    registrationBatchId: 0,
    sportId: 0,
  });
  const [captainsOnly, setCaptainsOnly] = useState<boolean>(false);
  const [leagueRuleOption, setLeagueRuleOption] = useState<number>(0);

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  /*** QUERIES ***/
  const { data: dataTemplates, loading: loadingTemplates } =
    useGetEmailTemplatesQuery({
      variables: {
        typeId: 1,
      },
    });

  const [getNumberOfPlayersOnTeams] = useGetNumberOfPlayersOnTeamsLazyQuery({
    variables: {
      teamIds: selectedTeams.map((team) => team.id),
    },
    onCompleted: (data) => {
      setNumberOfPlayers(data.getNumberOfPlayersOnTeams);
    },
  });

  const { data, loading, error } = useDivisionsForEmailDivisionToolQuery({
    variables: {
      divisionFilters: {
        regions:
          divisionFilters.regionId !== 0
            ? [divisionFilters.regionId]
            : undefined,
        registrationBatchId: divisionFilters.registrationBatchId,
        sportId: divisionFilters.sportId,
        day: divisionFilters.day,
      },
    },
    nextFetchPolicy: "cache-first",
    onCompleted(data) {
      setDivisions(data.divisions);
    },
  });

  const { data: divisionById } = useDivisionByIdForDivisionEmailToolQuery({
    variables: {
      divisionId: divisionId,
    },
    skip: divisionId === 0,
  });

  /*** MUTATIONS ***/
  const [sendDivisionEmail] = useSendDivisionEmailMutation();

  useEffect(() => {
    dispatch(getSports({}));
    dispatch(getRegistrationBatch({}));
  }, []);

  useEffect(() => {
    if (divisionById == null) {
      return;
    }
    const countryCode =
      divisionById.division.session?.league?.sportFormat?.region?.country
        ?.countryCode || "CA";
    const ruleId =
      divisionById.division.session?.league?.sportFormat?.ruleId || 0;
    if (iframeRef.current && emailBody !== undefined) {
      const formattedBody = `${header}${emailBody.body
        .replace(
          /%%button_(https:\/\/[a-zA-Z0-9._%+-\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
          (_, link, text) => {
            return emailButton(link, text);
          }
        )
        .replace(/%%ruleButton_([a-zA-Z0-9\s._%+-]+)%%/g, (_, text) => {
          return emailButton(`${rootUrl}/rules/${ruleId}`, text);
        })
        .replace(/%%ruleText_([a-zA-Z0-9\s._%+-]+)%%/g, (_, text) => {
          return emailHyperLink(`${rootUrl}/rules/${ruleId}`, text);
        })}${footer(countryCode)}`;
      const blob = new Blob([formattedBody], { type: "text/html" });
      iframeRef.current.src = URL.createObjectURL(blob);
    }
  }, [emailBody, divisionById]);

  async function handleSendEmail() {
    if (selectedTeams.length === 0) {
      dispatch(displayAlertError("Please select at least 1 team."));
      setOpenConfirmDialog(false);
      return;
    }
    const result = EmailTemplateSchema.safeParse(emailBody);
    if (result.success) {
      getNumberOfPlayersOnTeams();
      setOpenConfirmDialog(true);
    } else {
      setOpenConfirmDialog(false);
      dispatch(
        displayAlertError(
          `${result.error.issues.map((issue) => issue.message).join(",\n")}`
        )
      );
    }
  }

  const sendEmail = async () => {
    if (divisionId) {
      sendDivisionEmail({
        variables: {
          divisionId: +divisionId,
          teamIds: selectedTeams.map((team) => team.id),
          subject: emailBody.subject,
          body: emailBody.body,
          captainsOnly: captainsOnly,
        },
        onCompleted(data, clientOptions) {
          if (data.sendDivisionEmail) {
            if (data.sendDivisionEmail.success) {
              dispatch(displayAlertSuccess(data.sendDivisionEmail.message));
            } else {
              dispatch(displayAlertWarning(data.sendDivisionEmail.message));
            }
          } else {
            dispatch(displayAlertSuccess("Something went wrong sending"));
          }
        },
        onError(error, clientOptions) {
          dispatch(displayAlertError(error.message));
        },
      });
    }
  };

  return (
    <main className="flex flex-col gap-4">
      <Headline1Variable>Division Emails</Headline1Variable>
      <div className="flex flex-row items-center justify-start gap-2 mr-5">
        <FormFieldSelect
          name="regionId"
          value={divisionFilters.regionId?.toString() || "0"}
          inputChange={(value) => {
            setDivisionFilters((prevState) => ({
              ...prevState,
              regionId: +value,
            }));
          }}
          label="Region"
          placeholder="Select Region"
        >
          {[{ id: "0", name: "All" }, ...selectedRegions]}
        </FormFieldSelect>
        <FormFieldSelect
          name="sportId"
          value={divisionFilters.sportId?.toString() || "0"}
          inputChange={(value) => {
            setDivisionFilters((prevState) => ({
              ...prevState,
              sportId: +value,
            }));
          }}
          label="Sport"
          placeholder="Select Sport"
        >
          {[...sports, ...[{ id: "0", name: "All" }]]}
        </FormFieldSelect>
        <FormFieldSelect
          name="registrationBatchId"
          value={divisionFilters.registrationBatchId?.toString() || "0"}
          inputChange={(value) => {
            setDivisionFilters((prevState) => ({
              ...prevState,
              registrationBatchId: +value,
            }));
          }}
          label="Registration Batch"
          placeholder="Select Registration Batch"
        >
          {[...registrationBatches, ...[{ id: "0", name: "All" }]]}
        </FormFieldSelect>
        <FormFieldSelect
          inputChange={(value: string) => {
            setDivisionFilters((prevState) => ({
              ...prevState,
              day: +value,
            }));
          }}
          label="Day Of Week"
          placeholder="Day Of Week"
          value={divisionFilters.day?.toString() || "7"}
        >
          {[
            { id: 7, name: "All" },
            { id: 0, name: "Sunday" },
            { id: 1, name: "Monday" },
            { id: 2, name: "Tuesday" },
            { id: 3, name: "Wednesday" },
            { id: 4, name: "Thursday" },
            { id: 5, name: "Friday" },
            { id: 6, name: "Saturday" },
          ]}
        </FormFieldSelect>
      </div>
      <div className="flex flex-row items-center justify-start gap-2">
        <div className="max-w-80 w-80">
          <FormFieldSelect
            name="templateSelect"
            value={emailBody?.id?.toString() ?? "0"}
            inputChange={(value) => {
              if (value === "0" || dataTemplates === undefined) {
                setEmailBody(emptyEmailTemplate);
              } else {
                const newEmail = dataTemplates.getEmailTemplates.find(
                  (template: EmailTemplate) => template.id === +value
                );
                if (newEmail) {
                  setEmailBody(newEmail);
                }
              }
            }}
            loading={loadingTemplates}
            label="Template"
            placeholder="Select Email Temaplte"
          >
            {[
              { id: 0, name: "Select a Template" },
              ...(dataTemplates === undefined
                ? []
                : dataTemplates.getEmailTemplates.map(
                    (template: EmailTemplate) => {
                      return { id: template.id, name: template.name };
                    }
                  )),
            ]}
          </FormFieldSelect>
        </div>
        <div className="max-w-80 w-80">
          <FormFieldSelect
            name="divisionSelect"
            value={divisionId?.toString() ?? "0"}
            inputChange={(value) => {
              setDivisionId(+value);
            }}
            loading={loading}
            label="Division"
            placeholder="Select Division"
          >
            {[{ id: 0, name: "Select a Division" }, ...divisions]}
          </FormFieldSelect>
        </div>
      </div>
      <div className="flex flex-col w-1/2 gap-2">
        <Headline2Variable>Division Teams</Headline2Variable>

        {divisionById && divisionById.division.teams.length > 0 && (
          <div className="flex flex-col gap-2">
            <CheckBox
              label="Select All"
              defaultChecked={false}
              checkedState={
                selectedTeams.length === divisionById.division.teams.length
              }
              inputChange={(val) => {
                if (val) {
                  setSelectedTeams(
                    divisionById.division.teams.map(
                      (team: { id: number; name: string }) => {
                        return { id: team.id, name: team.name };
                      }
                    )
                  );
                } else {
                  setSelectedTeams([]);
                }
              }}
              id="select-all"
            />
            <div className="grid justify-between max-w-4xl grid-cols-3 gap-2">
              {divisionById.division.teams.map((team, index: number) => {
                return (
                  <div
                    key={index}
                    className="flex flex-row"
                  >
                    <CheckBox
                      label={`${team.isIndyTeam == true ? "(FA) " : ""}${
                        team.name
                      }`}
                      labelClassName="truncate"
                      defaultChecked={false}
                      checkedState={
                        selectedTeams.find(
                          (teamChecked) => teamChecked.id === team.id
                        )
                          ? true
                          : false
                      }
                      inputChange={(val) => {
                        setSelectedTeams(
                          selectedTeams.find(
                            (teamChecked) => teamChecked.id === team.id
                          )
                            ? selectedTeams.filter(
                                (teamChecked) => teamChecked.id !== team.id
                              )
                            : [
                                ...selectedTeams,
                                { id: +team.id, name: team.name },
                              ]
                        );
                      }}
                      id={team.name}
                    />
                  </div>
                );
              })}
            </div>
            <Separator />
            <CheckBox
              label="Email Team Captains Only"
              defaultChecked={false}
              checkedState={captainsOnly}
              inputChange={(val) => {
                setCaptainsOnly(val);
              }}
              id="captains-only"
            />
          </div>
        )}
      </div>
      <div className="flex flex-row justify-between w-full gap-4">
        <div className="flex flex-col w-1/2 gap-4">
          <div>
            <FormFieldControlled
              onChange={(value) => {
                setEmailBody({
                  ...emailBody,
                  subject: value.target.value,
                });
              }}
              value={emailBody.subject}
              label="Subject"
            />
          </div>
          <div className="mb-[46px]">
            <ReactQuill
              theme="snow"
              value={emailBody.body}
              onChange={(text: string) =>
                setEmailBody((prev) => ({
                  ...prev,
                  body: text,
                }))
              }
              style={{ height: "400px", width: "100%" }}
            />
          </div>

          <Button
            variant="primary"
            onClick={() => handleSendEmail()}
          >
            Send
          </Button>
        </div>
        <div className="flex flex-col w-1/2 gap-4 ">
          <div className="flex flex-col">
            <Headline2Variable>
              Email Variables (click to copy)
            </Headline2Variable>
            {/** Email Variables */}
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2 w-[360px]">
                <Body1 className="w-32">First Name:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%first_name%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className="w-fit bg-info-90">%%first_name%%</Body1>
              </div>
              <div className="flex flex-row gap-2 w-[360px]">
                <Body1 className="w-32">Team Name:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%team_name%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className="w-fit bg-info-90">%%team_name%%</Body1>
              </div>
              <div className="flex flex-row gap-2 w-[360px]">
                <Body1 className="w-32">Day of week:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%day_of_week%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className="w-fit bg-info-90">%%day_of_week%%</Body1>
              </div>
              <div className="flex flex-row gap-2 w-[360px]">
                <Body1 className="w-32">Sport:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%sport%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className="w-fit bg-info-90">%%sport%%</Body1>
              </div>
              <div className="flex flex-row gap-2 w-[360px]">
                <Body1 className="w-32">Division Name:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%division_name%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className="w-fit bg-info-90">%%division_name%%</Body1>
              </div>
              <div className="flex flex-row gap-2 w-[360px]">
                <Body1 className="w-32">Email:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%email%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className="w-fit bg-info-90">%%email%%</Body1>
              </div>
              <div className="flex flex-row gap-2 w-[360px]">
                <Body1 className="w-32">Address:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%address%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className="w-fit bg-info-90">%%address%%</Body1>
              </div>
              <div className="flex flex-row gap-2 w-[360px]">
                <Body1 className="w-32">Website Link:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%website_link%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className="w-fit bg-info-90">%%website_link%%</Body1>
              </div>
              <div className="flex flex-row items-center">
                <div className="flex flex-row gap-2 min-w-[360px]">
                  <Body1 className="min-w-32 whitespace-nowrap">
                    League Rules:
                  </Body1>
                  <IconButton
                    sx={{
                      "&:hover": { color: "var(--primary-80)" },
                      height: "24px",
                      width: "24px",
                    }}
                    onClick={() => {
                      switch (leagueRuleOption) {
                        case 0: {
                          navigator.clipboard.writeText("%%ruleLink%%");
                          break;
                        }
                        case 1: {
                          navigator.clipboard.writeText("%%ruleText_text%%");
                          break;
                        }
                        case 2:
                          {
                            navigator.clipboard.writeText(
                              "%%ruleButton_text%%"
                            );
                          }
                          break;
                        default: {
                          navigator.clipboard.writeText("%%ruleLink%%");
                          break;
                        }
                      }
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </IconButton>
                  <Body1 className="w-fit bg-info-90 h-fit">
                    {(() => {
                      switch (leagueRuleOption) {
                        case 0:
                          return "%%ruleLink%%";
                        case 1:
                          return "%%ruleText_text%%";
                        case 2:
                          return "%%ruleButton_text%%";
                        default:
                          return "%%ruleLink%%";
                      }
                    })()}
                  </Body1>
                </div>
                <FormFieldSelect
                  className="w-32 h-fit"
                  name="leagueRuleOption"
                  label=""
                  placeholder="Select "
                  value={leagueRuleOption?.toString() ?? "0"}
                  inputChange={(value: string) => {
                    setLeagueRuleOption(+value);
                  }}
                >
                  {[
                    { id: "0", name: "Link" },
                    { id: "1", name: "Link with text" },
                    { id: "2", name: "Button" },
                  ]}
                </FormFieldSelect>
              </div>

              <div className="flex flex-col gap-1">
                <div className="flex flex-row gap-2 w-[360px]">
                  <Body1 className="w-32">Button:</Body1>
                  <IconButton
                    sx={{
                      "&:hover": { color: "var(--primary-80)" },
                      height: "24px",
                      width: "24px",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText("%%button_link_text%%");
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </IconButton>
                  <Body1 className="w-fit bg-info-90">
                    %%button_link_text%%
                  </Body1>
                </div>
                <Body1>%%button_https://jamsports.com_Home%%</Body1>
              </div>
            </div>
          </div>
          <div className="max-w-[600px]">
            <iframe
              ref={iframeRef}
              title="Email Preview"
              style={{ width: "100%", height: "600px", border: "none" }}
            />
          </div>
        </div>
      </div>
      <Dialog
        open={openConfirmDialog}
        onOpenChange={setOpenConfirmDialog}
      >
        <DialogContent
          className="max-w-80"
          onAbort={() => console.log("nope")}
          onDragExit={() => console.log("exit")}
        >
          <DialogHeader>
            <DialogTitle>Confirm Division Email</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <div className="flex flex-col">
              <Body1>
                This will send an email to{" "}
                {captainsOnly ? selectedTeams.length : numberOfPlayers} people.
              </Body1>
            </div>
          </DialogDescription>
          <div className="flex flex-row gap-2">
            <DialogClose asChild>
              <Button
                className="w-full"
                variant={"primary"}
                onClick={() => {
                  setOpenConfirmDialog(false);
                  sendEmail();
                }}
              >
                Confirm
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button
                className="w-full"
                variant="negative"
              >
                Cancel
              </Button>
            </DialogClose>
          </div>
        </DialogContent>
      </Dialog>
    </main>
  );
};

export default EmailToolDivisions;
