import React, { useEffect, useMemo, useState } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Button from "../UI/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import {
  getVenuesCount,
  getVenuesManagement,
} from "../../app/venueMasterSlice";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTable, useSortBy, Column } from "react-table";
import { Pagination, Venue } from "../../types/types";
import moment from "moment";
import classes from "./Vendors.module.css";
import Link1 from "../UI/Text/Link/Link1";
import TablePagination from "../UI/Pagination/Pagination";
import { LoadingMaterialUI } from "../UI";
import ActionsCell from "../UI/ActionCell";
import FormField from "../UI/FormField/FormField";
import dayjs from "dayjs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../UI/shadcn/select";

const options = [
  { id: 1, text: "Edit" },
  { id: 2, text: "Create sub-venue" },
];

const Venues = () => {
  const publicUrl = process.env.PUBLIC_URL;
  //Pagination Page Size

  const dispatch = useDispatch<AppDispatch>();
  const {
    isLoading,
    venueListManagement,
    venueListCount,
    selectedRegions,
  }: any = useSelector((state: RootState) => state.venueMaster);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<null | Pagination>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [pageSize, setPageSize] = useState<number>(10);

  const handleChangePagination = (value: number) => {
    setPagination({
      pageCount: pagination?.pageCount,
      pageSize: pagination?.pageSize,
      page: value - 1,
    });
  };

  const handleChangePaginationRows = (value: number) => {
    const pageCount = Math.ceil(venueListCount / value);
    setPagination({
      pageCount: pageCount,
      pageSize: value,
      page: 0,
    });
    setPageSize(value);
  };

  const handleSearch = (search: string) => {
    const regionsId = selectedRegions.map((region: any) => +region.id);
    dispatch(
      getVenuesCount({
        regionId: regionsId,
        typeId: null,
        vendorId: null,
        search: search,
      })
    );

    if (pagination)
      dispatch(
        getVenuesManagement({
          venueFilters: {
            regionId: regionsId,
            typeId: null,
            vendorId: null,
            search: search,
          },
          page: pagination.page,
          pageSize: pagination.pageSize,
        })
      );
  };

  useEffect(() => {
    handleSearch(search || "");
  }, [dispatch, selectedRegions]);

  useEffect(() => {
    if (venueListCount) {
      const page = 0;
      const pageCount = Math.ceil(venueListCount / pageSize);
      setPagination({
        page,
        pageSize,
        pageCount,
      });
    }
  }, [venueListCount]);

  useEffect(() => {
    handleSearch(search || "");
  }, [pagination, selectedRegions]);

  const handleCreateVendor = () => {
    // navigate(`${publicUrl}/ops/venue-management/${venueId}`, { state: { venueId } });
    navigate(`${publicUrl}/ops/venue-management`);
  };

  const handleSelectedOption = (row: any, idx: number) => {
    if (idx === 1) {
      // edit
      if (!row.values["parentVenue.id"].props.children) {
        navigate(`${publicUrl}/ops/venue-management/${row.values.id}`, {
          state: { vendorId: row.values.id },
        });
      } else {
        navigate(
          `${publicUrl}/ops/sub-venue-management/${row.values["parentVenue.id"].props.children}/${row.values.id}`
        );
      }
    }
    if (idx === 2) {
      // create sub-venue
      navigate(`${publicUrl}/ops/sub-venue-management/${row.values.id}`);
    }
  };

  const COLUMNS: Column<Venue>[] = [
    {
      Header: "VenueId#",
      accessor: "id",
    },
    {
      Header: "ParentVenueId#",
      id: "parentVenue.id",
      accessor: (d: any) => {
        return <div>{d.parentVenue ? d.parentVenue.id : null}</div>;
      },
    },
    {
      Header: "Venue",
      id: "name",
      accessor: (d: any) => {
        return (
          <IconButton
            onClick={() => {
              // edit
              if (!d.parentVenue?.id) {
                navigate(`${publicUrl}/ops/venue-management/${d.id}`, {
                  state: { vendorId: d.id },
                });
              } else {
                navigate(
                  `${publicUrl}/ops/sub-venue-management/${d.parentVenue.id}/${d.id}`
                );
              }
            }}
          >
            <Link1>{d["name"]}</Link1>
          </IconButton>
        );
      },
    },
    {
      Header: "Region",
      id: "region.id",
      accessor: (d: any) => {
        return <div>{d.venueDetail?.region.name}</div>;
      },
    },
    {
      Header: "Type",
      id: "venueType.id",
      accessor: (d: any) => {
        return <div>{d.venueType.name}</div>;
      },
    },
    {
      Header: "Vendor",
      id: "vendor.id",
      accessor: (d: any) => {
        return <div>{d.venueDetail?.vendor.name}</div>;
      },
    },
    {
      Header: "Address",
      id: "address",
      accessor: (d: any) => {
        return <div>{d.venueDetail?.["address"]}</div>;
      },
    },
    {
      Header: "Parent",
      id: "parentVenue",
      accessor: (d: any) => {
        return <div>{d.parentVenue ? d.parentVenue.name : "None"}</div>;
      },
    },
    {
      Header: "Created",
      id: "createdAt",
      accessor: (d: any) => {
        return <div>{dayjs(d.createdAt).format("YYYY-MM-DD")}</div>;
      },
    },
    {
      Header: "Last Edit",
      id: "updatedAt",
      accessor: (d: any) => {
        return <div>{dayjs(d.updatedAt).format("YYYY-MM-DD HH:mm")}</div>;
      },
    },
    {
      Header: " ",
      Cell: (data: any) => {
        return (
          <ActionsCell
            row={data.row}
            options={options}
            handleSelectedOption={handleSelectedOption}
          />
        );
      },
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => {
    return venueListManagement;
  }, [venueListManagement]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
        initialState: {
          hiddenColumns: ["id", "parentVenue.id"],
        },
      },
      useSortBy
    );
  const vendorTable = (
    <TableContainer
      className={classes.shift__container}
      sx={{
        maxHeight: "80vh",
        width: "95vw",
        maxWidth: "100%",
        bgcolor: "white",
        padding: "20px",
        borderRadius: "12px",
      }}
    >
      <Table
        stickyHeader
        aria-label="sticky table"
        {...getTableProps()}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  sx={{
                    bgcolor: "white",
                    color: "var(--textColor)",
                    fontSize: "16px",
                    textAlign: "left",
                    fontWeight: 550,
                    borderColor: "var(--table-border)",
                    paddingBottom: "5px",
                  }}
                  {...column
                    .getHeaderProps
                    // column.getSortByToggleProps()
                    ()}
                >
                  <div className={classes.headAlign}>
                    {column.render("Header")}
                    {/* <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )
                    ) : (
                      ''
                    )}
                  </span> */}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                hover
                sx={{
                  borderBottom: "5px",
                  borderWidth: "2px",
                }}
                role="checkbox"
                tabIndex={-1}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{
                        textAlign: "left",
                        fontSize: "15px",
                        color: "var(--textColor)",
                        // borderWidth: '2px',
                        borderColor: "var(--table-border)",
                        paddingBottom: "8px",
                        paddingTop: "8px",
                      }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {pagination && (
        <div className="flex justify-end mt-4">
          <TablePagination
            page={pagination?.page || 0}
            pageCount={pagination?.pageCount || 0}
            pageSize={pagination?.pageSize || 0}
            onChange={handleChangePagination}
            onRowsPerPageChange={handleChangePaginationRows}
          />
        </div>
      )}
    </TableContainer>
  );

  if (isLoading) {
    return (
      <div>
        <LoadingMaterialUI />
      </div>
    );
  }

  return (
    <main>
      <div className="flex flex-row justify-between w-full mb-4">
        <Headline1Variable>Venues</Headline1Variable>
        <div className="flex flex-row h-full gap-4">
          <div className="h-[90%] flex gap-2">
            <FormField
              className="w-96"
              initialValue={search || ""}
              inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(e.target.value);
              }}
              name="startBuffer"
              placeholder="Search"
            ></FormField>
            <Button
              variant="secondary"
              onClick={() => {
                handleSearch(search || "");
              }}
            >
              <span> Search</span>
            </Button>
          </div>

          <Button
            variant="primary"
            onClick={handleCreateVendor}
          >
            <AddIcon
              fontSize="large"
              sx={{
                width: "18px",
                height: "18px",
              }}
            />
            <span> New Venue</span>
          </Button>
        </div>
      </div>
      {venueListManagement.length > 0 && (
        <div className={classes.table__venues}>{vendorTable}</div>
      )}
      {!(venueListManagement.length > 0) && (
        <div className={classes.table__contracts}>No Data Found</div>
      )}
    </main>
  );
};

export default Venues;
